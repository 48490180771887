import React, { Component } from 'react';
import { Carousel } from "antd";
import request from "../../utils/req";
import abnnerM from '../../asset/images/abnnerM.png'
import './index.less';
import navgaitonto from '../../utils/navgaitonto';

class App extends Component {
    state = {
        visbled: false,
        data: []
    }
    componentDidMount() {
        this.getBanner();
    }

    getBanner = () => {
        request('getCocBanner', { num: 6 }, res => {
            if (res.code === 1000) {

                if (res.result.data.length === 0) {
                    this.setState({
                        data: [{ id: 0, img: abnnerM }]
                    });
                } else {
                    this.setState({
                        data: res.result.data
                    });
                }
            } else {
                this.setState({
                    data: [{ id: 0, img: abnnerM }]
                });
            }
        });
    }
    render() {
        const { data, current = 0 } = this.state;
        return (
            <div style={{ position: 'relative', height: 650, backgroundColor: "#ddd" }} >
                <Carousel ref={dom => this.carousel = dom} afterChange={e => this.setState({ current: e })} autoplay dots={false}>
                    {data.map(item => (
                        <img src={item.img} key={item.id} onClick={() => {
                            if (item.url) {
                                navgaitonto.openT(item.url.indexOf('http://') !== -1 || item.url.indexOf('https://') !== -1 ? `${item.url}` : `http://${item.url}`);
                            }
                        }} className="banner" />
                    ))}
                </Carousel>
                <div className="checkouDotse">
                    {data.length > 1 ? data.map((item, index) => (
                        <div key={item.id} onClick={e => this.carousel.goTo(index)} className={current === index ? "checkouDotsList" : "checkouDotsLists"} />
                    )) : ""}
                </div>
            </div>
        )
    }
}

export default App;