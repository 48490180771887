import React, { Component } from "react";
import { message } from "antd";
import TabBar from "../../../components/TabBar";
import Footer from "../../../components/Footer";
import PartyNewsCard from "../../../components/PartyContentNewsCard";
import ActCard from "../../../components/ActCard";
import NewList from "../../../components/newList";
import BannerImg from "../../../components/BannerImg";
import request from "../../../utils/req";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import navgaitonto from "../../../utils/navgaitonto";

import "./index.less";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

class App extends Component {
  state = {
    display: true,
    width: 1140,
    newsList: [],
    cateList: []
  };
  componentDidMount() {
    this.getList();
    this.getCateList();
  }

  getList() {
    request("articleList", { num: 10, top: 1, type: 3 }, (res) => {
      if (res.code === 1000) {
        this.setState({
          newsList: res.result.data,
          loading: false,
        });
      } else if (res.code === -1) {
        this.setState({
          loading: false,
        });
        message.error("网络请求失败，请检查当前网络");
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }

  /* 标题 */
  renderTitle = (title, url) => {
    return (
      <div className="comTitle">
        <div className="comTitleR">{title}</div>
        <div className="comTitleL" onClick={() => navgaitonto.open('/partyNewsList', url)}>查看更多{'>'}</div>
      </div>
    );
  };


  getCateList = () => {
    request("articleCateList", { num: 999, type: 3 }, (res) => {
      if (res.code === 1000) {
        this.setState({
          cateList: res.result.data
        });
      }
    });
  }

  render() {
    const { newsList, cateList } = this.state;

    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: newsList.length > 4 ? 5 : newsList.length,
      slidesToScroll: 5,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
    return (
      <div className="index">
        {/* 头部 */}
        <TabBar index="党建中心" />
        {/* 轮播图 */}
        <BannerImg />
        <div className="container">
          {/* 特别推荐 */}
          <div className="special">
            <div className="left">特别推荐</div>
            <div className="right">
              <div className="btn btnL"></div>

              <div
                className="newList"
                style={{
                  width: this.state.width + "px",
                  display: this.state.display ? "block" : "none",
                }}
              >
                <Slider {...settings}>
                  {newsList.map((item) => {
                    return (
                      <div className="newItems"
                        onMouseDown={e => this.setState({
                          dateNow: Date.now()
                        })}
                        onClick={(e) => {
                          let isgo = this.state.dateNow < Date.now() - 200;
                          if (!isgo) {
                            navgaitonto.open('/partyNewsDetail', `id=${item.id}`);
                          }
                        }}>
                        <div className="cocNewsBgc" style={{ backgroundImage: `url(${item.imgs[0]})`, paddingBottom: '75%' }}></div>
                        {/* <img className="imgs" src={item.imgs[0]}></img> */}
                        <div className="title overHidden">{item.title}</div>
                        <div className="content">
                          <div dangerouslySetInnerHTML={{ __html: item.content }} className="twoOverHidden" />
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>

              <div className="btn btnR"></div>
            </div>
          </div>
          {/* 党建新闻跟活动会议 */}
          <div className="newsAct">
            {/* 党建新闻 */}
            <div className="partyListnews">
              {
                cateList[0] && <PartyNewsCard cate={cateList[0]} />
              }
            </div>
            {/* 活动会议 */}
            <div className="partyListAct">
              <ActCard />
            </div>
          </div>
          {/* 党建新闻 */}
          {
            cateList.map((item, idnex) => (
              idnex !== 0 &&
              <div className="cateNews" key={item.id}>
                {this.renderTitle(item.name, `cate=${item.id}`)}
                <div>
                  <NewList data={item} />
                </div>
              </div>
            ))
          }
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
