import React, { Component } from 'react';
import { Button, message, Carousel } from "antd";
import request from "../../utils/req";
import navgaitonto from "../../utils/navgaitonto";
import Loading from "../Loading";
import dates from "../../utils/time/time";

import './index.less';

class App extends Component {
    state = {
        data1: [],
        data2: [],
        loading: false
    }
    componentDidMount() {
        this.setState({
            loading: true,
        });
        request("articleList", { num: 5, cate_id: this.props.cate.id }, (res) => {
            if (res.code === 1000) {
                this.setState({
                    data1: res.result.data,
                    loading: false,
                });
            } else if (res.code === -1) {
                this.setState({
                    loading: false,
                });
                message.error("网络请求失败，请检查当前网络");
            } else {
                this.setState({
                    loading: false,
                });
            }
        });
    }
    /* 标题 */
    renderTitle = (title, cate) => {
        let params = false;
        console.log(cate);
        if (cate) {
            params = `cate=${cate.cate.id}`;
        }
        return (
            <div className='comTitle'>
                <div className="comTitleR">
                    {title}
                </div>
                <div className="comTitleL" onClick={() => navgaitonto.open('/cocNewsList', params)}>
                    查看更多{'>'}
                </div>
            </div>
        );
    }

    render() {
        const { data2, data1, loading, colorList = [] } = this.state;
        return (
            <div className="actCards">
                {this.renderTitle(this.props.cate.name, data1[0])}
                {
                    loading ? <Loading /> :
                        <div className="listCard">
                            {
                                data1.map(item => (
                                    < div className="listItem" key={item.id} onClick={() => {
                                        this.setState({
                                            colorList: [...colorList, item.id]
                                        });
                                        navgaitonto.open('/cocNewsDetail', `id=${item.id}`);
                                    }}>
                                        <div className="photo" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <div className="cocNewsBgc" style={{ backgroundImage: `url(${item.imgs[0]})`, paddingBottom: '100%' }}></div>
                                            {/* <img src={item.imgs[0]} style={{ maxWidth: "100%", maxHeight: '100%' }} /> */}
                                        </div>
                                        <div className="content">
                                            <div className="title twoOverHidden" style={{ color: colorList.indexOf(item.id) === -1 ? "#333" : "#666" }}>
                                                {item.title}
                                            </div>
                                            <div className="dates">
                                                发布时间：{dates.getTime(item.release_time)}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                }
            </div>
        )
    }
}

export default App;