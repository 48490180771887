import React, { Component } from "react";
import { Pagination, message } from "antd";
import TabBar from "../../../components/TabBar";
import Footer from "../../../components/Footer";
import HotNews from "../../../components/HotNews";
import Detail from "../../../components/Detail";
import NewsHotRec from "../../../components/NewsHotRec";
import navgaitonto from "../../../utils/navgaitonto";

import request from "../../../utils/req";
import "./index.less";

class App extends Component {
  state = {
    articleInfo: {},
    newsList: [],
  };
  componentWillMount() {
    // let qs = window.location.search.substring(1);
    // let id = "";
    // if (qs.length > 0) {
    //   id = qs.substring(qs.indexOf("=") + 1).toUpperCase();
    // }
    this.getInfo(this.props.location.query.id);
    this.getList();
  }

  getInfo(id) {
    request("articleInfo", { id: id }, (res) => {
      if (res.code === 1000) {
        this.setState({
          articleInfo: res.result,
        });
      } else if (res.code === -1) {
        this.setState({
          loading: false,
        });
        message.error("网络请求失败，请检查当前网络");
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }
  getList() {
    request("articleList", { num: 4, top: 1, type: 1 }, (res) => {
      if (res.code === 1000) {
        this.setState({
          newsList: res.result.data,
          loading: false,
        });
      } else if (res.code === -1) {
        this.setState({
          loading: false,
        });
        message.error("网络请求失败，请检查当前网络");
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }
  /* 标题 */
  renderTitle = (title, url) => {
    return (
      <div className="comTitle">
        <div className="comTitleR">{title}</div>
        <div className="comTitleL" style={{ cursor: "pointer" }} onClick={() => navgaitonto.go('/cocNews')}>查看更多</div>
      </div>
    );
  };
  render() {
    const { articleInfo, newsList } = this.state;
    const { type } = this.props.location.query;
    console.log(newsList, "newsList");
    return (
      <div className="cocNewsInfo">
        {/* 头部 */}
        <TabBar index={type ? "商会概况" : '商会新闻'} />
        <div className="container">
          <div className="redBar"></div>
          {type ?
            <div style={{ fontSize: 18, color: '#333' }} className="location"><span style={{ cursor: 'pointer' }} onClick={() => navgaitonto.go('/CocDesc')}>商会概况</span>&gt;<span style={{ cursor: 'pointer' }} onClick={() => navgaitonto.go(type == 1 ? '/CocEvent' : type == 2 ? '/Cochonor' : '/HomeShow')}>{type == 1 ? "商会大事件" : type == 2 ? "商会荣誉" : '家乡风采'}</span></div>
            : <div style={{ fontSize: 18, color: '#333' }} className="location"><span style={{ cursor: 'pointer' }} onClick={() => navgaitonto.go('/cocNews')}>商会新闻</span>&gt;新闻详情</div>
          }
          <div className="detailContent">
            <div className="detail">
              <Detail data={articleInfo}></Detail>
            </div>
            <div className="hotNews">
              <NewsHotRec list={newsList}></NewsHotRec>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
