import React, { Component } from 'react';
import { Button, message, Carousel } from "antd";
import request from "../../utils/req";
import navgaitonto from "../../utils/navgaitonto";
import Loading from "../Loading";
import dates from "../../utils/time/time";

import './index.less';

class App extends Component {
    state = {
        data: [],
        loading: false
    }
    componentDidMount() {
        this.getDate()
    }

    getDate = () => {
        this.setState({
            loading: true,
        });
        request("actList", { type: 3, num: 5, p: 1, }, (res) => {
            if (res.code === 1000) {
                this.setState({
                    loading: false,
                    data: res.result.data,
                });
            } else {
                this.setState({
                    loading: false,
                });
            }
        });
    };
    /* 标题 */
    renderTitle = (title, url) => {
        return (
            <div className='comTitle'>
                <div className="comTitleR">
                    {title}
                </div>
                <div className="comTitleL" onClick={() => navgaitonto.open('/act')}>
                    查看更多{'>'}
                </div>
            </div>
        );
    }

    rennderGoTime(start, newDate, end) {
        if (start < newDate || dates.dateDay(newDate) === dates.dateDay(start)) {
            if (end > newDate || dates.dateDay(newDate) === dates.dateDay(start)) {
                return "进行中";
            } else {
                return "已完成";
            }
        } else {
            return "报名中"
        }
    }

    render() {
        const { data, loading } = this.state;

        return (
            <div className="actCard">
                {this.renderTitle('活动会议')}
                {
                    loading ? <Loading /> :
                        <div className="listCard">
                            {
                                data.map(item => (
                                    < div className="listItem" key={item.id} onClick={e => navgaitonto.open('/ActDetail', `id=${item.id}`)}>
                                        <div className="photo" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <div className="cocNewsBgc" style={{ backgroundImage: `url(${item.imgs[0]})`, paddingBottom: '100%' }}></div>
                                        </div>
                                        <div className="content">
                                            <div className="title twoOverHidden">
                                                <span style={{ background: this.rennderGoTime(item.start_time, Date.parse(new Date()) / 1000, item.end_time) === "已完成" ? '#FFB100' : "#B80011", padding: '0 5px', borderRadius: 6 }} className="goto"> {this.rennderGoTime(item.start_time, Date.parse(new Date()) / 1000, item.end_time)}</span> {item.title}
                                            </div>
                                            <div className="dates">
                                                <div>
                                                    已报名：{item.join_num}人
                                                </div>
                                                <div>
                                                    截止时间：{dates.getTime(item.apply_deadline_time)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                }
            </div>
        )
    }
}

export default App;