import React, { Component } from "react";
import TabBar from "../../../components/TabBar";
import Footer from "../../../components/Footer";
import CityChoose from "../../../components/CityChoose";
import Opportunityitem from "../../../components/Opportunityitem";
import RepostiTitons from "../../../components/repostiTitons";
import Loading from "../../../components/Loading";
import noDataImg from '../../../asset/images/no_data.png';
import buyBsiImg from '../../../asset/images/buyBsi.png'; // 采购
import gBsiImg from '../../../asset/images/gBsiImg.png'; // 供应
import xBsiImg from '../../../asset/images/xBsiImg.png'; // 需求
import zBsiImg from '../../../asset/images/zBsiImg.png'; // 招标

import ctiyoption from '../../../components/CityChoose/option.json';
import { Pagination, message, Input, Popover, Select, Menu, Dropdown } from "antd";

import { SearchOutlined, CloseOutlined, DownOutlined, CaretDownOutlined } from '@ant-design/icons';
import "./index.less";
import request from "../../../utils/req";

const { Option } = Select;
class App extends Component {
    state = {
        hotBoCateList: [],
        moreBtnText: "更多",
        cityList: [],
        cityLists: '',
        data: [],
        page: 1,
        total: 0,
        selectTabs: [],
        industry: [],
        type: '0',
        time: '0'
    };


    componentDidMount() {
        // console.log(JSON.parse(this.props.location.query));
        const industry = [];
        let type = '';
        let title = '';
        if (this.props.location.query.id) {
            industry.push(Number(this.props.location.query.id));
            this.setState({
                selectTabs: [{ id: Number(this.props.location.query.id), name: this.props.location.query.name }]
            });
        }
        if (this.props.location.query.type) {
            type = this.props.location.query.type;
        }
        if (this.props.location.query.title) {
            title = this.props.location.query.title;
        }
        this.setState({
            type,
            title,
            industry
        }, () => {
            this.getBisList();
        });
        this.getHotBoCateList();

        const value = [];
        ctiyoption.map(item => {
            item.children.map(items => {
                if (items.name !== "市辖区") value.push(items);
            })
        })
        this.setState({
            cityList: value
        })
    }

    /* 标题 */
    renderTitle = (title, url) => {
        return (
            <div className="comTitle">
                <div className="comTitleR">{title}</div>
                <div className="comTitleL">查看更多</div>
            </div>
        );
    };

    getBisList = () => {
        const { page, type, cityLists, cityList, time, title, industry } = this.state;
        this.setState({
            loading: true
        });
        let region = '';
        cityList.map(item => {
            if (item.name === cityLists) region = item.id;
        })
        request('boList', { num: 8, type: type, region: region, p: page, time: time, title: title, industry: industry.join(',') }, res => {
            if (res.code === 1000) {
                this.setState({
                    data: res.result.data,
                    total: res.result.total,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
                message.error("网络请求失败，请检查当前网络");
            }
        });
    }
    getHotBoCateList = () => {
        request('getSysCategoryList', {}, res => {
            if (res.code === 1000) {
                this.setState({
                    hotBoCateList: res.result
                });
            } else {
            }
        })
    }

    renderPopover = (List) => {
        const { industry, selectTabs } = this.state;
        return <div className="hotBoCateList">
            {List.map(item => (
                industry.indexOf(item.id) === -1 && <div className="leftItemCard" key={item.id} onClick={e => {
                    if (industry.length === 3) {
                        message.error("最多筛选3个经营类目");
                        return;
                    }
                    this.setState({ selectTabs: [...selectTabs, item], industry: [...industry, item.id], page: 1 }, this.getBisList);
                }}>
                    {item.name}
                </div>
            ))}

        </div>
    }
    render() {
        const { hotBoCateList, moreBtnText, cityList, data, page, total, loading, title, selectTabs, industry, type, visbledPhoto } = this.state;

        const menu = (
            <Menu style={{ width: 200 }} onChange={e => console.log(e)}>
                <Menu.Item key="3" onClick={() => this.setState({ type: '3', page: 1 }, this.getBisList)}>
                    需求商机
              </Menu.Item>
                <Menu.Item key="2" onClick={() => this.setState({ type: '2', page: 1 }, this.getBisList)}>
                    采购商机
              </Menu.Item>
                <Menu.Item key="1" onClick={() => this.setState({ type: '1', page: 1 }, this.getBisList)}>
                    供应商机
              </Menu.Item>
                <Menu.Item key="4" onClick={() => this.setState({ type: '4', page: 1 }, this.getBisList)}>
                    招标商机
              </Menu.Item>
            </Menu>
        );
        return (
            <div className="businessList">
                <RepostiTitons isMember={true} visbled={visbledPhoto} url='pages/tabbarDump/bo/index' onChange={e => this.setState({ visbledPhoto: false })} />
                {/* 头部 */}
                <TabBar index="商会商机" />
                <div className="container">
                    <div className="tabBorder" />
                    {this.props.location.query.type && <Dropdown overlay={menu}>
                        <div className="checkBtn">
                            切换类型<CaretDownOutlined style={{ color: '#ccc', marginLeft: 10 }} />
                        </div>
                    </Dropdown>}
                    {this.props.location.query.type && <img src={type === '1' ? gBsiImg : type === '2' ? buyBsiImg : type === '3' ? xBsiImg : zBsiImg} style={{ width: "100%" }} />}
                    {
                        this.props.location.query.type && type !== '4' && <div className="postBtn" onClick={() => this.setState({ visbledPhoto: true })}>
                            我也要发布{type === '1' ? "供应" : type === '2' ? "采购" : '需求'}
                        </div>
                    }
                    {/* 搜索框 */}
                    <div className="serchInput">
                        <Input onChange={e => this.setState({ title: e.target.value })} value={title} onPressEnter={e => this.setState({ page: 1 }, this.getBisList)} style={{ borderColor: "#B80011" }} placeholder="请输入商机关键词" prefix={<SearchOutlined style={{ color: '#ccc', fontSize: 18 }} />} />
                        <div className='seacrchBtn' onClick={e => this.setState({ page: 1 }, this.getBisList)}>
                            搜索
                        </div>
                    </div>

                    {/* 已选择tabs */}
                    {selectTabs.length > 0 && <div className="tabslist">
                        {selectTabs.map(item => (
                            <div className="tabs" key={item.id} >
                                {item.name}<CloseOutlined style={{ color: '#999999', marginLeft: 11 }} onClick={() => {
                                    this.setState({ selectTabs: selectTabs.filter(items => items.id !== item.id), industry: industry.filter(items => items !== item.id), page: 1 }, this.getBisList);
                                }} />
                            </div>))}
                    </div>}
                    <div className="boCateCard">
                        <div className="leftItem">
                            {
                                hotBoCateList.map((item, index) => (
                                    (index < 16 || moreBtnText == "收起") && industry.indexOf(item.id) === -1 ?
                                        <Popover content={this.renderPopover(item.children)} key={item.id} placement="bottomRight">
                                            <div className="leftItemCard" onClick={e => {
                                                if (industry.length === 3) {
                                                    message.error("最多筛选3个经营类目");
                                                    return;
                                                }
                                                this.setState({ selectTabs: [...selectTabs, item], industry: [...industry, item.id], page: 1 }, this.getBisList);
                                            }} >
                                                {item.name}
                                            </div>
                                        </Popover>
                                        : ""
                                ))
                            }
                        </div>
                        <div className="rightMoreBtn" onClick={() => this.setState({ moreBtnText: moreBtnText === "更多" ? "收起" : "更多" })}>
                            {moreBtnText}<DownOutlined style={{ marginLeft: 10, transform: moreBtnText === "更多" ? '' : 'rotate(180deg)' }} />
                        </div>
                    </div>

                    <div className="screen">
                        {!this.props.location.query.type && <Select placeholder='商机筛选' size="large" onChange={e => this.setState({ page: 1, type: e }, this.getBisList)} style={{ width: 120 }}>
                            <Option value="0">
                                全部
                            </Option>
                            <Option value="3">
                                需求商机
                            </Option>
                            <Option value="2">
                                采购商机
                            </Option>
                            <Option value="1">
                                供应商机
                            </Option>
                            <Option value="4">
                                招标商机
                            </Option>
                        </Select>}
                        <Select notFoundContent="暂无数据" allowClear showSearch placeholder='地区筛选' size="large" onChange={e => this.setState({ page: 1, cityLists: e }, this.getBisList)} style={{ width: 120 }}>
                            {cityList.map(item => (
                                <Option value={item.name}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                        <Select placeholder='时间筛选' onChange={e => this.setState({ page: 1, time: e }, this.getBisList)} size="large" style={{ width: 120 }}>
                            <Option value="0">全部</Option>
                            <Option value="1">一周内</Option>
                            <Option value="2">一个月内</Option>
                            <Option value="3">三个月内</Option>
                        </Select>
                    </div>

                    <div className="cardList">
                        {loading ? <Loading /> : data.length === 0 ? <img src={noDataImg} style={{ margin: '0 auto', display: 'block', height: 400 }} /> : data.map(item => (
                            <Opportunityitem type={item.type} data={item} key={item.id} />
                        ))}
                    </div>
                    {
                        total > 8 && <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                            <Pagination showSizeChanger={false} pageSize={8} current={page} total={total} onChange={e => this.setState({ page: e }, this.getBisList)} />
                        </div>
                    }
                </div>
                <Footer />
            </div >
        );
    }
}

export default App;
