import React, { Component } from "react";
import dateUtils from "../../utils/time/time";
import request from "../../utils/req";
import navgaitonto from "../../utils/navgaitonto";
import companyLogo from '../../asset/images/companyLogo.png'; // 招标
import "./index.less";

class App extends Component {

  state = {
    companyList: []
  }

  componentDidMount() {

    request('getCocCompanyList', { num: 7, }, res => {
      if (res.code === 1000) {
        this.setState({
          companyList: res.result.data,
        });
      } else {
      }
    });
  }



  render() {
    const { companyList = [], visbledPhoto, params } = this.state;
    console.log(visbledPhoto);
    return (
      <div className="shopListR">
        <div className="shopCaptionR">
          <div className="captionTxtR">
            推荐公司
          </div>
          <div className="captionDuoR" onClick={() => navgaitonto.open('/CompanyList')}>查看更多&gt;</div>
        </div>
        <div className="shopListImgR">
          {
            companyList.map(item => (
              <div className="details" key={item.id} onClick={() => this.props.onClick(item.id)}>
                <div className="detailsLogo" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <img src={item.company_logo || companyLogo} style={{ maxWidth: "100%", maxHeight: '100%' }} />
                </div>
                <div className="company">
                  <div className="companyName">{item.company_name}</div>
                  <div className="industry">
                    {item.industry && item.industry.map(items => (
                      <div className="industryList overHidden" key={item}>{items}</div>
                    ))}
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}

export default App;
