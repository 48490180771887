import React, { Component } from 'react';
import request from '../../utils/req';
import './index.less';


const boxList1 = ['联系电话', '商会地址', '商会邮箱'];
const boxList2 = ['', '', ''];
const boxList3 = ['常见问题', '意见反馈'];


class App extends Component {
    state = {
        datas: [],
        imgs: '',
        data: {}
    }
    componentDidMount() {
        // console.log(this.props);
        request('getCocInfo', {}, res => {
            if (res.code === 1000) {
                console.log(123123);
                boxList2[0] = res.result.phone || '暂无';
                boxList2[1] = res.result.area || '暂无';
                boxList2[2] = res.result.email || '暂无';
                this.setState({
                    datas: boxList2,
                    imgs: res.result.qrcode,
                    data: res.result
                });
            }
        });
    }

    renderItemList = (title, list) => {
        return (
            <div>
                {title && <div className="title">{title}</div>}
                {list.map((item, index) => {
                    return title === '' && index === 1 ? <div style={{ maxWidth: 600, lineHeight: '19px', height: 40, marginBottom: 0 }} className="twoOverHidden value" key={index}>{item}</div> : <div className="value" key={index}>{item}</div>
                })}
            </div>
        );
    }

    render() {
        const {
            data
        } = this.state;
        return (
            <div style={{ height: 235, width: "100%" }}>
                <div className="footer">
                    {/* 第一排 */}
                    <div className='box1'>
                        <div className="firstVlaues overHidden">客服电话：{data.phone}</div>
                        <div className="firstVlaues overHidden">客服邮箱：{data.email}</div>
                        <div className="firstVlaues overHidden">商会地址：{data.area}</div>
                        <div className="fengex overHidden" />
                        {/* Copyright © 1996-2020 SINA Corporation京ICP备18062017号-2 ICP证：京B2-20201359  京公网安备  */}
                        <div className="firstVlaues overHidden"><a style={{ color: '#FFFFFF' }} href="https://beian.miit.gov.cn" target="_block"> {data.domain_info}</a></div>
                        <div className="firstVlaues overHidden">长沙商企云算科技有限公司 版权所有</div>
                    </div>
                    {/* 第二排 */}
                    {/* <div className='box2'>
                        {this.renderItemList('', this.state.datas)}
                    </div> */}
                    {/* 第三排 */}
                    {/* <div className='box3'>
                        {this.renderItemList('帮助中心', boxList3)}
                    </div> */}
                    {/* 第四排 */}
                    <div className="box4">
                        <img className="img" src={this.state.imgs} />
                        <div className="text">
                            扫码进入小程序
                     </div>
                    </div>
                </div>
            </div>)
    }
}

export default App;