/* eslint-disable react/no-unused-state */
import React, { PureComponent } from 'react';
import { Cascader, message } from 'antd';

import option from './option.json';

class CityChoose extends PureComponent {
  state = {
    options: option,
    value: [],
    maxLength: 3,
  };

  componentDidMount() {
    const options = option;
    options.map(item => {
      item.children.map(items => {
        items.children = items.children.filter(itemse => itemse.name !== '市辖区');
      });
    });
    this.setState({
      options: options,
      maxLength: this.props.maxLength ? this.props.maxLength : 3,
    });
  }

  mapOptions = options => {
    const value = this.props.value;
    const address = [];
    for (const i in options) {
      if (Number(options[i].id) == Number(value[0])) {
        address.push(options[i].name);
        for (const is in options[i].children) {
          if (Number(options[i].children[is].id) == Number(value[1])) {
            address.push(options[i].children[is].name);
            for (const ise in options[i].children[is].children) {
              if (Number(options[i].children[is].children[ise].id) == Number(value[2])) {
                address.push(options[i].children[is].children[ise].name);
                break;
              }
            }
            break;
          }
        }
        break;
      }
    }
    console.log(address);
    this.props.onChange(value, address.join(''));
  };

  handleChange = (value, e) => {
    const { maxLength } = this.state;
    // if (value.length !== 0 && value.length != 3 && maxLength === 3) {
    //   message.error('请选择至三级地区');
    //   return;
    // }
    const val = [];
    if (e.length !== 0) {
      e.map(item => {
        val.push(item.name);
      });
    }
    this.props.onChange(value, val.join(''));
  };

  render() {
    const { disabled = false, width = 300 } = this.props;
    return (
      <Cascader
        // eslint-disable-next-line object-shorthand
        style={{ width: width }}
        size="large"
        options={this.state.options}
        placeholder="地区筛选"
        expandTrigger="hover"
        fieldNames={{ label: 'name', value: 'id', children: 'children' }}
        onChange={this.handleChange}
        defaultValue={this.props.value}
        changeOnSelect
        value={this.props.value}
        disabled={disabled}
      />
    );
  }
}

export default CityChoose;
