import React, { Component } from 'react';
import { Button, message, DatePicker } from 'antd';
import TabBar from '../../../components/TabBar';
import Footer from '../../../components/Footer';
import dates from '../../../utils/time/time';
import nodataImg from '../../../asset/images/no_data.png';
// import Swiper from '../../../components/Swiper';
import Loading from '../../../components/Loading';
import request from '../../../utils/req';
import navgaitonTo from '../../../utils/navgaitonto';

import './index.less';
import navgaitonto from '../../../utils/navgaitonto';

class App extends Component {
    state = {
        cocInfo: [],
        index: 1
    }

    componentWillMount() {
        if (this.props.location.pathname === "/Cochonor") {
            this.setState({
                index: 2
            });
        }
        this.getDate();
    }

    getDate = (year) => {
        let years = '2020';
        if (year) {
            years = dates.getyear(Date.parse(year) / 1000);
        }
        this.setState({
            loading: true
        });
        let type = 5;
        if (this.props.location.pathname === "/Cochonor") {
            type = 6;
        }
        request('cmsTimeList', { year: years, type: type }, res => {
            if (res.code === 1000) {
                this.setState({
                    cocInfo: res.result,
                    loading: false
                });
            } else if (res.code === -1) {
                this.setState({
                    loading: false
                });
                message.error("网络请求失败，请检查当前网络");
            }
        });
    }

    componentDidMount() {
        console.log(this.props);
    }


    /* 标题 */
    renderTitle = (title, url) => {
        return (
            <div className='comTitle'>
                <div className="comTitleR">
                    {title}
                </div>
                <div className="comTitleL">
                    查看更多{'>'}
                </div>
            </div>
        );
    }
    render() {
        const { index, cocInfo, loading } = this.state;
        const tabbarlist = ['商会简介', '商会大事件', '商会荣誉', '家乡风采', '关联商会'];
        return (<div className="cocEvent">
            {/* 头部 */}
            <TabBar index="商会概况" />
            <div className="container">
                <div className='topBd'></div>
                <div className='content'>
                    {/* 左边 */}
                    <div className='sideNaw'>
                        {
                            tabbarlist.map((item, indexs) => (
                                <div onClick={() => {
                                    if (indexs === 1) {
                                        navgaitonTo.go('/CocEvent');
                                    } else if (indexs === 2) {
                                        navgaitonTo.go('/Cochonor');
                                    } else if (indexs === 3) {
                                        navgaitonTo.go('/HomeShow');
                                    } else if (indexs === 4) {
                                        navgaitonTo.go('/CocRlt');
                                    } else if (indexs === 0) {
                                        navgaitonTo.go('/CocDesc');
                                    }
                                }} className={index === indexs ? 'nawName navBarBar' : 'nawName'}>{item}</div>
                            ))
                        }
                    </div>
                    {/* 右边内容 */}
                    <div className='contentBox'>
                        {/* 连动 */}
                        <div className='date'> <DatePicker size="large" onChange={e => this.getDate(e)} style={{ width: 100 }} placeholder="2020" picker="year" /></div>
                        {loading ? <Loading /> : cocInfo.length !== 0 && cocInfo[0].children.map(item => (
                            <div className='dateBox' key={item.data}>
                                {/* 6月 */}
                                <div className='dateYue'>{item.data}月</div>
                                {
                                    item.children.map(items => (
                                        <div key={items.id} onClick={() => navgaitonTo.open('/cocNewsDetail', `id=${items.id}&type=${index}`)}>
                                            <div className='dateText-1'><span style={{ marginRight: 28 }}>{dates.getday(items.record_time)}日</span>{items.title}</div>
                                            {
                                                items.imgs.length !== 0 && <div className='listImg-1'>
                                                    {
                                                        items.imgs.map(itemse => (
                                                            <img src={itemse} key={itemse} className='listImg-2' />
                                                        ))
                                                    }
                                                </div>
                                            }

                                        </div>
                                    ))
                                }
                            </div>
                        )) || <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <img src={nodataImg} />
                            </div>}
                    </div>
                </div>
            </div>
            <Footer />
        </div>)
    }
}

export default App;