import React, { Component } from "react";
import { Button, message, Carousel } from "antd";
import Loading from "../Loading";
import request from "../../utils/req";
import dates from "../../utils/time/time";
import navgaitonto from "../../utils/navgaitonto";

import "./index.less";

class App extends Component {
  state = {};
  render() {
    console.log(this.props);
    const { list = [] } = this.props;
    return (
      <div className="newsHotRec">
        <div className="titleMore">
          <span className="hot">热门推荐</span>
          <span className="lookMores" style={{ cursor: "pointer" }} onClick={() => navgaitonto.go('/cocNews')}>查看更多&gt;</span>
        </div>
        <div className="list">
          {list.map((item) => {
            return (
              <div className="itemView" style={{ cursor: "pointer" }} onClick={() => {
                // console.log(`id=${item.id}`);
                navgaitonto.go('/cocNewsDetail', `id=${item.id}`);
              }}>
                <p className="title">{item.title}</p>
                <img className="itemImg" src={item.imgs[0]}></img>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default App;
