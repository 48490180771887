import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.less";
import { message } from "antd";
import Loading from "../Loading";
import request from "../../utils/req";
import navgaitonto from "../../utils/navgaitonto";
class App extends Component {
  state = {
    loading: false,
    data: [],
    display: true,
    width: 1200,
  };

  componentDidMount() {
    request("memberShowList", { num: 20 }, (res) => {
      if (res.code === 1000) {
        this.setState({
          data: res.result.data,
        });
      } else if (res.code === -1) {
        this.setState({
          loading: false,
        });
        message.error("网络请求失败，请检查当前网络");
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }

  render() {
    const { loading, data } = this.state;
    const settings = {
      infinite: true,
      slidesToShow: data.length > 4 ? 5 : data.length,
      slidesToScroll: 1,
      autoplay: true,
      speed: 3000,
      autoplaySpeed: 3000,
      cssEase: "linear"
    };
    return (
      <div className="menberShow">
        <div
          style={{
            width: this.state.width + "px",
            display: this.state.display ? "block" : "none",
          }}
        >
          {loading ? (
            <Loading />
          ) : data.length > 5 ? <Slider {...settings}>
            {
              data.map((item) => (
                <div className="menberShowItem" key={item.id}
                  onMouseDown={e => this.setState({
                    dateNow: Date.now()
                  })} onClick={(e) => {
                    let isgo = this.state.dateNow < Date.now() - 200;
                    if (!isgo) {
                      navgaitonto.open('/MemberInfo', `id=${item.coc_user_id}`);
                    }
                  }}>
                  <div className="photo" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={item.photo} style={{ maxWidth: "100%", maxHeight: '100%' }} />
                  </div>
                  <div className="name overHidden">{item.name}</div>
                  {item.position?.name && (
                    <div className="position overHidden">
                      {item.position.name}
                    </div>
                  )}
                  {item.company?.company_name && (
                    <div className="position overHidden">
                      {item.company?.company_name}
                    </div>
                  )}
                </div>
              ))}
          </Slider> :
              <div style={{ display: "flex" }}>
                {data.map((item) => (
                  <div className="menberShowItem" key={item.id}
                    onMouseDown={e => this.setState({
                      dateNow: Date.now()
                    })} onClick={(e) => {
                      let isgo = this.state.dateNow < Date.now() - 200;
                      if (!isgo) {
                        navgaitonto.open('/MemberInfo', `id=${item.coc_user_id}`);
                      }
                    }}>
                    <div className="photo" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <img src={item.photo} style={{ width: "100%", height: '100%' }} />
                    </div>
                    <div className="name overHidden">{item.name}</div>
                    {item.position?.name && (
                      <div className="position overHidden">
                        {item.position.name}
                      </div>
                    )}
                    {item.company?.company_name && (
                      <div className="position overHidden">
                        {item.company?.company_name}
                      </div>
                    )}
                  </div>
                ))}
              </div>
          }
        </div>
      </div>
    );
  }
}

export default App;
