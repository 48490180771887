import React, { Component } from 'react';
import { Map, Marker } from 'react-amap';
import axios from 'axios';

import { Input, message } from 'antd';

const mapKey = 'eecc88004620163d116d8ba6273d8a7f '; // 需要自己去高德官网上去申请
class Address extends Component {
  state = {
    longitude: 112.971075, // this.props.lon,
    latitude: 28.199598, // this.props.lat,
    value: '', // this.props.value,
  };

  componentDidMount() {
    if (this.props.value !== '') {
      this.getPosition(this.props.value);
      this.setState({
        value: this.props.value,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps.value !== this.state.value);
    if (nextProps.value !== this.state.value) {
      console.log(nextProps.value);
      this.getPosition(nextProps.value);
      this.setState({
        value: nextProps.value,
      });
    }
  }

  getPosition = async e => {
    console.log(e,'asdasdasdasdas123123');
    if (e && e !== '') {
      const res = await axios.get(
        `https://restapi.amap.com/v3/geocode/geo?key=cf17a8ed264630de94484fd8e0dae7f3&address=${e}`,
        { key: mapKey, address: e },
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );
      console.log(res.data.status, "asdasdadad");
      console.log(res.data, "asdasdadad");
      if (res.data.status == '1') {
        if (res.data.geocodes.length == 0) {
          if (!this.props.isEdit) {
          }
          return;
        }
        if (res.data.geocodes[0].location !== '') {
          const location = res.data.geocodes[0].location.split(',');
          this.setState({
            longitude: location[0],
            latitude: location[1],
          });
          console.log(1233123123123123);
          this.props.onChange({ lat: location[1], lon: location[0] });
        }
      } else {
      }
    }
  };

  postAddress = async e => {
    console.log(123123213231321321);
    const res = await axios.get(
      `https://restapi.amap.com/v3/geocode/regeo?key=01eda9f155f48543ae3a07430f9687e9&location=
      ${[e.lnglat.lng, e.lnglat.lat].join(',')}`,
      {},
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
    if (res.data.status == '1') {
      this.props.onClick(res.data.regeocode, { lon: e.lnglat.lng, lat: e.lnglat.lat });
    } else {
      message.error('获取地址失败，请检查当前网络');
    }
    console.log(res);
  };

  render() {
    const { longitude, latitude } = this.state;
    const { width = 400, height = 400, isEdit } = this.props;
    return (
      <div style={{ width: width, height: height, pointerEvents: isEdit ? 'none' : '' }}>
        <Map
          amapkey={mapKey}
          zoom={15}
          center={{ longitude: longitude, latitude: latitude }}
          events={{
            created: ins => { },
            click: e => {
              console.log(e);
              this.postAddress(e);
              this.setState({
                longitude: e.lnglat.lng,
                latitude: e.lnglat.lat,
              });
            },
          }}
        >
          <Marker
            position={{
              longitude: longitude ? longitude : this.props.lon,
              latitude: latitude ? latitude : this.props.lat,
            }}
          />
        </Map>
      </div>
    );
  }
}

export default Address;
