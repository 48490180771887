import React, { Component } from 'react';
import { Button, message, Spin } from 'antd';

import './index.less';

class App extends Component {

    componentDidMount() {
        console.log(this.props);
    }

    render() {
        return (
            <div className="loading">
                <Spin size="large" />
            </div>)
    }
}

export default App;