import React, { Component } from "react";
import { message } from "antd";
// import

import request from '../../utils/req';
import navgaitonTo from '../../utils/navgaitonto';

import "./index.less";

const tabbarList = [
  "首页",
  "党建中心",
  "商会新闻",
  "商会会员",
  "商会商城",
  "商会商机",
  "活动会议",
  "商会概况",
];

class App extends Component {
  state = {
    selectTab: "首页",
    data: {}
  };

  componentWillMount() {
    console.log(window.location);
    if (this.props) {
      this.setState({
        selectTab: this.props.index
      });
    }
    let datas = navgaitonTo.getTbar();
    if (datas.id) {
      this.setState({
        data: datas
      });
    } else {
      request('getCocInfo', {}, res => {
        if (res.code === 1000) {
          navgaitonTo.setTbar(res.result);
          this.setState({
            data: res.result
          });
          document.title = res.result.name
        } else if (res.code === -1) {
          message.error("网络请求失败，请检查当前网络");
        }
      });
    }
  }
  // 点击事件
  onClickTab = (item) => {
    const { selectTab } = this.state;
    if (selectTab === item) {
      return;
    }
    if (item === '商会商城') {
      message.warning("商城正在开发中，敬请期待");
      return;
    }
    if (item === "首页") {
      navgaitonTo.go('/home');
    }
    if (item === "商会新闻") {
      navgaitonTo.go('/cocNews');
    } else if (item === "党建中心") {
      navgaitonTo.go('/partyContent');
    } else if (item === "活动会议") {
      navgaitonTo.go('/act');
    } else if (item === "商会会员") {
      navgaitonTo.go('/member');
    } else if (item === "商会商机") {
      navgaitonTo.go('/Business');
    } else if (item === "商会概况") {
      navgaitonTo.go('/CocDesc');
    }
    this.setState({
      selectTab: item,
    });
  };

  // 渲染tabbar
  renderTabBarList = () => {
    const { selectTab } = this.state;
    return tabbarList.map((item) => (
      <div
        key={item}
        style={item == "商会概况" ? { margin: 0 } : {}}
        className={selectTab === item ? "selecItems" : "items"}
        onClick={(e) => this.onClickTab(item)}
      >
        {item}
      </div>
    ));
  };

  render() {
    const { data } = this.state;
    return (
      <div className="pages">
        <div className="tabBar">
          {/* logo */}
          <img src={data.logo} className="logo" onClick={() => navgaitonTo.go('/home')} />
          {/* 需求 */}
          <div className="cocName" onClick={() => navgaitonTo.go('/home')}>{data.name}</div>
          {/* tabbar */}
          {this.renderTabBarList()}
        </div>
      </div>
    );
  }
}

export default App;
