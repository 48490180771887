import React, { Component } from 'react';
import './index.less';
import { Button, message, Carousel } from "antd";


import Loading from "../Loading";
import request from "../../utils/req";
import navgaitonto from "../../utils/navgaitonto";
import nextImg from '../../asset/images/next.png';
class App extends Component {
    state = {
        data: [],
        loading: false
    }
    componentDidMount() {
        this.setState({
            loading: true,
        });
        request("articleList", { num: 9, type: 4, top: 1 }, (res) => {
            if (res.code === 1000) {
                const data1 = [];
                const data2 = [];
                const data3 = [];

                res.result.data.map((item, index) => {
                    if (index < 3) {
                        data1.push(item);
                    } else if (index < 6) {
                        data2.push(item);
                    } else {
                        data3.push(item);
                    }
                });
                if (data1.length > 0 && data2.length > 0 && data3.length > 0) {
                    this.setState({
                        data: [data1, data2, data3],
                    });
                } else if (data1.length > 0 && data2.length > 0) {
                    this.setState({
                        data: [data1, data2],
                    });
                } else if (data1.length > 0) {
                    this.setState({
                        data: [data1],
                    });
                }
                this.setState({
                    loading: false,
                });
            } else if (res.code === -1) {
                this.setState({
                    loading: false,
                });
                message.error("网络请求失败，请检查当前网络");
            } else {
                this.setState({
                    loading: false,
                });
            }
        });
    }

    render() {
        const { loading, data } = this.state;
        return (
            <div className="homeBanner" >
                {
                    loading ? <Loading /> :
                        <>
                            <div className="rightbtn homeBannerbtn" onClick={e => this.carousels.prev()}>
                                <img src={nextImg} />
                            </div>
                            <Carousel ref={dom => this.carousels = dom} className="box" autoplay dots={false}>
                                {
                                    data.map((items, index) => (
                                        <div key={index} className="homeVides" >
                                            {
                                                items.map(item => (
                                                    <div key={item.id} className="homeVidese" onClick={() => navgaitonto.open('/cocNewsDetail', `id=${item.id}`)}>
                                                        <img src={item.imgs[0]} style={{ width: '100%', height: '100%' }} />
                                                        <div className="floating">
                                                            <div style={{ width: '100%', textAlign: "center" }} className="overHidden">
                                                                {item.title}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ))
                                }
                            </Carousel>
                            <div className="leftbtn homeBannerbtn" onClick={e => this.carousels.next()}>
                                <img src={nextImg} />
                            </div>
                        </>
                }
            </div>)
    }
}

export default App;