import React, { Component } from "react";
import { Pagination, message } from "antd";
import ReactCrop from 'react-image-crop';
import TabBar from "../../../components/TabBar";
import Footer from "../../../components/Footer";
import CateNews1 from "../../../components/CateNews1";
import CateNews2 from "../../../components/CateNews2";
import NewList from "../../../components/newList";
import HotNews from "../../../components/HotNews";
import NewListImg from "../../../components/NewListImg";
import request from "../../../utils/req";
import dates from "../../../utils/time/time";
import PartyNewsCard from "../../../components/PartyContentNewsCard";
import BannerImg from "../../../components/BannerImg";
import speciallyBgc from '../../../asset/images/specially.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.less";
import navgaitonto from "../../../utils/navgaitonto";

class App extends Component {
  state = {
    display: true,
    width: 1200,
    data: [],
    cateList: []
  };

  componentDidMount() {
    this.getCateList();
    request("articleList", { num: 6, top: 1, type: 1 }, (res) => {
      if (res.code === 1000) {
        this.setState({
          data: res.result.data,
          loading: false,
        });
      }
    });
  }

  getCateList = () => {
    request("articleCateList", { num: 999, type: 1 }, (res) => {
      if (res.code === 1000) {
        this.setState({
          cateList: res.result.data
        });
      }
    });
  }

  /* 标题 */
  renderTitle = (title, url) => {
    return (
      <div className="comTitle">
        <div className="comTitleR">{title}</div>
        <div className="comTitleL" onClick={() => navgaitonto.open('/cocNewsList', url)}>查看更多{'>'}</div>
      </div>
    );
  };
  render() {
    const { data, cateList, crop = { aspect: 16 / 9 } } = this.state;
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "0",
      slidesToShow: 3,
      speed: 500,
      autoplay: true,
      slidesToScroll: 1
    };
    console.log(cateList);
    return (
      <div className="cocNewsindex">
        {/* 头部 */}
        <TabBar index="商会新闻" />
        {/* 轮播图 */}
        <BannerImg />
        <div className="container">
          <img src={speciallyBgc} style={{ width: "100%", margin: '30px auto' }} />
          {/* 特别推荐 */}
          <div className="activitySwiper">
            {data.length > 3 &&
              <Slider {...settings}>
                {data.map((item, index) => {
                  return (
                    <div className="activitySwiperItem" key={item.id}
                      onMouseDown={e => this.setState({
                        dateNow: Date.now()
                      })}
                      onClick={(e) => {
                        let isgo = this.state.dateNow < Date.now() - 200;
                        if (!isgo) {
                          navgaitonto.open('/cocNewsDetail', `id=${item.id}`)
                        }
                      }}>
                      {/* <ReactCrop src={item.imgs[0]} style={{ width: "100%", height: '100%', cursor: "pointer" }} crop={crop} /> */}
                      <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div className="cocNewsBgc" style={{ backgroundImage: `url(${item.imgs[0]})` }}></div>
                        {/* <img src={item.imgs[0]} style={{ maxWidth: "100%", maxHeight: '100%' }} /> */}
                      </div>
                      <div style={{ width: "100%", position: "relative" }}>
                        <div className="tipsBgc overHidden" style={{ width: "100%", bottom: 0 }}>
                          <span className="overHidden">{item.title}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider> ||
              <div style={{ display: 'flex', justifyContent: "center" }}>
                {data.map((item, index) => {
                  return (
                    <div className="activitySwiperItem" style={{ marginLeft: index !== 3 ? 15 : 0, transform: data.length < 3 ? '' : index === 1 ? 'scale(1)' : 'scale(0.8)' }} key={item.id} onClick={() => navgaitonto.open('/cocNewsDetail', `id=${item.id}`)}>
                      <div className="cocNewsBgc" style={{ backgroundImage: `url(${item.imgs[0]})`,paddingBottom: '50%' }}></div>
                      <div style={{ width: "100%", position: "relative" }}>
                        <div className="tipsBgc overHidden" style={{ width: "100%", bottom: 0 }}>
                          <span className="overHidden">{item.title}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            }

          </div>
          {/* 新闻分类1 */}
          {
            (cateList.length > 3 || cateList.length === 1 || cateList.length === 2) && <CateNews1 data={cateList[0]} />
          }

          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
            {
              cateList.length > 2 && <div style={{ width: 596, height: 684, border: '1px solid #ccc' }}>
                <PartyNewsCard cate={cateList.length === 3 ? cateList[0] : cateList[1]} url="/cocNewsList" />
              </div>
            }
            {
              cateList.length > 2 && <div style={{ width: 574, border: '1px solid #ccc' }}>
                <CateNews2 cate={cateList.length === 3 ? cateList[1] : cateList[2]} />
              </div>
            }

          </div>
          {/* 新闻分类3 */}
          {
            cateList.length > 1 &&
            cateList.map((item, index) => (
              (cateList.length === 2 ? index > 0 : cateList.length === 3 ? index > 1 : index > 2) &&
              <div className="cateNews" key={item.id}>
                {this.renderTitle(item.name, `cate=${item.id}`)}
                <div>
                  <NewList data={item} url={true} />
                </div>
              </div>
            ))
          }
        </div>
        <Footer />
      </div >
    );
  }
}

export default App;
