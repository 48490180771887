import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, browserHistory } from 'react-router';
import 'antd/dist/antd.css';
import './index.less';
import 'react-image-crop/dist/ReactCrop.css';
import Index from './pages/Home/Index'; // 首页
import CocNews from './pages/CocNews/Index'; // 商会新闻首页
import CocNewsList from './pages/CocNews/List'; // 商会新闻查看更多
import CocNewsDetail from './pages/CocNews/Detail';//新闻详情页
import Member from './pages/Member/Index'; // 会员风采
import MemberList from './pages/Member/List'; // 会员列表
import MemberInfo from './pages/Member/Info'; // 会员详情
import PartyContent from './pages/PartyContent/Index'; // 商会商机
import PartyNewsDetail from './pages/PartyContent/Detail'; // 党建新闻
import Act from './pages/Act/Index'; // 商会活动
import ActDetail from './pages/Act/Detail'; // 商会活动详情
import Business from './pages/Business/Index'; // 商会商机  
import BusinessList from './pages/Business/List'; // 商会1列表
import CocEvent from './pages/CocDesc/CocEvent'; // 商会大事件
import HomeShow from './pages/CocDesc/HomeShow'; // 商会风采
import CocRlt from './pages/CocDesc/CocRlt'; // 商会关联
import CocDesc from './pages/CocDesc/CocDesc'; // 商会简介
import CompanyList from './pages/Business/CompanyList'; // 商会简介


ReactDOM.render(
  <Router history={browserHistory}>
    <Route path='/' component={Index} />
    <Route path='/home' component={Index} />
    <Route path='/cocNews' component={CocNews} />
    <Route path='/cocNewsList' component={CocNewsList} />
    <Route path='/partyNewsList' component={CocNewsList} />
    <Route path='/cocNewsDetail' component={CocNewsDetail} />
    <Route path='/member' component={Member} />
    <Route path='/partyContent' component={PartyContent} />
    <Route path='/partyNewsDetail' component={PartyNewsDetail} />
    <Route path='/act' component={Act} />
    <Route path='/MemberList' component={MemberList} />
    <Route path='/MemberInfo' component={MemberInfo} />
    <Route path='/Business' component={Business} />
    <Route path='/BusinessList' component={BusinessList} />
    <Route path='/ActDetail' component={ActDetail} />
    {/* 事件 */}
    <Route path='/CocEvent' component={CocEvent} />
    {/* 荣誉 */}
    <Route path='/Cochonor' component={CocEvent} />
    <Route path='/HomeShow' component={HomeShow} />
    <Route path='/CocRlt' component={CocRlt} />
    <Route path='/CocDesc' component={CocDesc} />
    <Route path='/CompanyList' component={CompanyList} />
    <Route path='*' component={Index} />
  </Router>,
  document.getElementById('root')
);