import React, { Component } from "react";
import { Pagination, message } from "antd";
import TabBar from "../../../components/TabBar";
import Footer from "../../../components/Footer";
import HotNews from "../../../components/HotNews";
import RepostiTitons from "../../../components/repostiTitons";
import NewsHotRec from "../../../components/NewsHotRec";
import request from "../../../utils/req";
import navgaitonto from "../../../utils/navgaitonto";
import dates from '../../../utils/time/time';
import "./index.less";

class App extends Component {
  state = {
    data: {
      imgs: ['']
    }
  };

  componentDidMount() {
    console.log(this.props.location.query.id);
    this.getDate(this.props.location.query.id);
  }

  getDate = (id) => {
    request('actInfo', { id: id }, res => {
      if (res.code === 1000) {
        this.setState({
          data: res.result
        });
      } else {
        message.error('网络请求失败，请检查当前网络');
      }
    })
  }

  render() {
    // window.open('你所要跳转的页面');
    const { data, visbledPhoto } = this.state;
    return (
      <div className="actInfo">
        <RepostiTitons visbled={visbledPhoto} url='coc/pages/activity/activity_detail/activity_detail' params={`id=${data.id}`} onChange={e => this.setState({ visbledPhoto: false })} />
        {/* 头部 */}
        <TabBar index="活动会议" />
        <div className="container">
          <div className="tabBorder" />
          <div style={{ fontSize: 18, color: '#333', marginTop: 20 }} className="location"><span style={{ cursor: 'pointer' }} onClick={() => navgaitonto.go('/act')}>活动会议</span>&gt;活动详情</div>

          <div className="actBody">
            <img src={data?.imgs?.length !== 0 ? data?.imgs[0] : ''} className="photo" />
            <div className="rightText">
              <div className="title twoOverHidden">
                {data.title}
              </div>
              <div className="valueBox">
                <div className="label">
                  活动地点<div style={{ marginLeft: 40, width: 380 }} className="overHidden">
                    {data.address}
                  </div>
                  {
                    data.price !== 0 && <div className="price">
                      ¥ {data.price}元/人
                  </div> || <div className="price">
                      免费
                  </div>
                  }
                </div>
                <div className="label">
                  活动时间
                  <div style={{ marginLeft: 40, width: 320 }} className="overHidden">
                    {dates.getTime(data.start_time)}~{dates.getTime(data.end_time)}
                  </div>
                </div>
                <div className="label">
                  报名截止
                  <div style={{ marginLeft: 40, width: 320 }} className="overHidden">
                    {dates.getTime(data.apply_deadline_time)}
                  </div>
                </div>
                <div className="label">
                  主办单位
                  <div style={{ marginLeft: 40, width: 320 }} className="overHidden">
                    {data.sponsor || "暂无"}
                  </div>
                </div>
                <div className="label" style={{ marginBottom: 5 }}>
                  参与对象
                  <div style={{ marginLeft: 40, width: 320 }} className="overHidden">
                    {data.target == 0 ? "全体人员" : data.target == 1 ? "商会全体人员" : "商会指定人员"}
                  </div>
                </div>
                <div className="bottom">
                  <div className="pmun">
                    已有{data.join_num}人报名
                  </div>
                  <div className="btn" onClick={() => this.setState({ visbledPhoto: true })}>
                    我要报名
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 详情title */}
          <div style={{
            marginTop: 30, marginBottom: 30, fontSize: 24,
            color: '#B80011'
          }}>
            活动详情
          </div>
          <div dangerouslySetInnerHTML={{ __html: data.content?.replace(/crossorigin="anonymous"/g , ' ') }} style={{ minHeight: 200, width: '100%', overflow: 'hidden' }}></div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
