import React, { Component } from 'react';
import TabPane from '../TabPane';
import './index.less';
import navgaitonto from "../../utils/navgaitonto";
import request from "../../utils/req";
import dates from '../../utils/time/time';
import noDataImg from '../../asset/images/no_data.png';
import Items from './Items';

class App extends Component {
    state = {
        data: []
    }
    componentDidMount() {
        this.getDate(0);
    }

    getDate = (type) => {
        request('boList', { num: 5, type: type }, res => {
            if (res.code === 1000) {
                this.setState({
                    data: res.result.data,
                });
            } else {
            }
        });
    }

    renderTitle = (title, url) => {
        return (
            <div className='comTitle'>
                <div className="comTitleR">
                    {title}
                    <TabPane data={['全部', '供应', '采购', '需求', '招标']} onChange={e => this.getDate(e)} />
                </div>
                <div onClick={() => navgaitonto.go(url)} className="comTitleL">
                    查看更多{'>'}
                </div>
            </div>
        );
    }
    renderType(type, bg) {
        if (type === 2) {
            if (bg) {
                return { backgroundColor: '#B3CFF6', color: '#0055B8' };
            }
            return "采购";
        }
        if (type === 3) {
            if (bg) {
                return { backgroundColor: '#F6B3B3', color: '#B80000' };
            }
            return "需求";
        }
        if (type === 1) {
            if (bg) {
                return { backgroundColor: '#B6F6B3', color: '#00B823' };
            }
            return "供应";
        }
        if (type === 4) {
            if (bg) {
                return { backgroundColor: '#FFDEB2', color: '#FCA500' };
            }
            return "招标";
        }
    }

    render() {
        const { showCode } = this.state;
        return (
            <div className="opportunity">
                {this.renderTitle("商会商机", '/Business')}
                <div className="card">
                    {
                        this.state.data.length === 0 ? <img src={noDataImg} style={{ margin: '0 auto', display: 'block' }} /> : this.state.data.map(item => (
                            <Items key={item.id} item={item} />
                        ))
                    }
                </div>
            </div>)
    }
}

export default App;