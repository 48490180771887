import React, { Component } from "react";
import dateUtils from "../../utils/time/time";

import "./index.less";

class App extends Component {
  render() {
    const { data = [], handClick } = this.props;
    return (
      <div className="cocNewsItem" onClick={() => handClick()}>
        <div className="newsImg" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src={data.imgs[0]} style={{ maxWidth: "100%", maxHeight: '100%' }} />
        </div>
        <div className="newsContent">
          <p className="title" style={{ WebkitBoxOrient: "vertical" }}>
            {data.title}
          </p>
          <p className="desc" style={{ WebkitBoxOrient: "vertical" }}>
            {data.content}
          </p>
          <div className="time">{dateUtils.getDate2(data.release_time)}</div>
        </div>
      </div>
    );
  }
}

export default App;
