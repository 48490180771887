
let coc_id = window.location.search?.split('=')[1]?.split('&')[0] || 3;
let tabbar = {};
function navgationTo(url, params) {
    if (params) {
        url = `${url}?coc_id=${coc_id}&${params}`;
    } else {
        url = `${url}?coc_id=${coc_id}`;
    }

    window.location.href = url;
}

function setCoc_id(id) {
    coc_id = id;
    console.log(coc_id, "setCoc_id");
}

function open(url, params) {
    if (params) {
        url = `${url}?coc_id=${coc_id}&${params}`;
    } else {
        url = `${url}?coc_id=${coc_id}`;
    }
    window.open(url);
}

function openT(url) {
    window.open(url);
}

function setTbar(data) {
    tabbar = data;
}

function getTbar() {
    return tabbar;
}


export default {
    go: navgationTo,
    set: setCoc_id,
    open,
    getTbar,
    setTbar,
    openT
}