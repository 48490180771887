import React, { Component } from 'react';
import { Button, message, Pagination } from 'antd';
import { FullscreenOutlined } from "@ant-design/icons";
import TabBar from '../../../components/TabBar';
import Footer from '../../../components/Footer';
import dates from '../../../utils/time/time';
import Maper from '../../../components/Maper';
import navgaitonTo from '../../../utils/navgaitonto';
// import Swiper from '../../../components/Swiper';
import request from '../../../utils/req';

import './index.less';

class App extends Component {
    state = {
        data: {},
        page: 1,
        total: 0
    }

    componentWillMount() {
        // navgaitonTo.go('/cocNews');
        request('getCocInfo', {}, res => {
            if (res.code === 1000) {
                this.setState({
                    data: res.result
                });
            } else if (res.code === -1) {
                message.error("网络请求失败，请检查当前网络");
            }
        });
    }


    render() {
        const { data, page, total } = this.state;
        const tabbarlist = ['商会简介', '商会大事件', '商会荣誉', '家乡风采', '关联商会'];

        return (<div className="cocDesc">
            {/* 头部 */}
            <TabBar index="商会概况" />
            <div className="container">
                <div className='topBd'></div>
                <div className='content'>
                    {/* 左边 */}
                    <div className='sideNaw'>
                        {tabbarlist.map((item, indexs) => (
                            <div onClick={() => {
                                if (indexs === 1) {
                                    navgaitonTo.go('/CocEvent');
                                } else if (indexs === 2) {
                                    navgaitonTo.go('/Cochonor');
                                } else if (indexs === 3) {
                                    navgaitonTo.go('/HomeShow');
                                } else if (indexs === 4) {
                                    navgaitonTo.go('/CocRlt');
                                } else if (indexs === 0) {
                                    navgaitonTo.go('/CocDesc');
                                }
                            }} className={indexs === 0 ? 'nawName navBarBar' : 'nawName'} key={item}>{item}</div>
                        ))}
                    </div>
                    {/* 右边内容 */}
                    <div className='containers'>
                        <div dangerouslySetInnerHTML={{ __html: data.describe }} style={{ minHeight: 200, width: '100%', marginBottom: 20 }}></div>
                        {data.organ_img && <div style={{ position: 'relative' }}>
                            <img src={data.organ_img} style={{ width: "100%" }} />
                            <div
                                onClick={() => navgaitonTo.openT(data.organ_img)}
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 0,
                                    width: 144,
                                    height: 66,
                                    fontSize: 20,
                                    fontFamily: 'Microsoft YaHei',
                                    fontWeight: 'bold',
                                    color: '#FFFFFF',
                                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer'
                                }}>
                                <FullscreenOutlined style={{ fontSize: 24, marginRight: 10 }} />  查看大图
                            </div>
                        </div>}
                        <div style={{ border: '1px solid #ccc', margin: '20px 0' }}>
                            <Maper
                                width="100%"
                                height={500}
                                isEdit={true}
                                value={data.area}
                                onChange={e => { }}
                            ></Maper>
                        </div>
                        <div className="title">
                            联系电话：{data.phone}
                        </div>
                        <div className="title">
                            地址：{data.area}
                        </div>
                        <img src={data.qrcode} style={{ width: 200 }} />
                        <div className="title">
                            微信扫一扫  查看更多商会信息
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div >)
    }
}

export default App;