import React, { Component } from "react";
import { Button, message, Carousel } from "antd";
import Loading from "../Loading";

import request from "../../utils/req";
import dates from "../../utils/time/time";
import navgaitonto from "../../utils/navgaitonto";

import "./index.less";

class App extends Component {
  state = {
    loading: false,
    data1: [],
    data2: [],
    current: 0
  };

  componentDidMount() {
    this.setState({
      loading: true,
    });
    request("articleList", { num: 13, top: 1, type: 1 }, (res) => {
      if (res.code === 1000) {
        const data1 = [];
        const data2 = [];

        res.result.data.map((item, index) => {
          if (index < 6) {
            data1.push(item);
          } else {
            data2.push(item);
          }
        });
        this.setState({
          data1,
          data2,
          loading: false,
        });
      } else if (res.code === -1) {
        this.setState({
          loading: false,
        });
        message.error("网络请求失败，请检查当前网络");
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }

  render() {
    const { loading, data1, data2, current, colorList = [] } = this.state;
    const contentStyle = {
      height: '160px',
      color: '#fff',
      lineHeight: '160px',
      textAlign: 'center',
      background: '#364d79',
    };
    return (
      <div className="hotNews">
        {loading ? (
          <Loading />
        ) : (
            <>
              {/* 轮播图 */}
              <div className="newsBanner">
                <div className="btn btn1" onClick={e => this.carousel.prev()} />
                <Carousel className="newsBannerItems" ref={dom => this.carousel = dom} autoplay dots={false} afterChange={e => this.setState({ current: e })}>
                  {data1.map((item, index) => (
                    <div className="newsBannerItem" key={item.id} onClick={() => navgaitonto.open('/cocNewsDetail', `id=${item.id}`)}>
                      <img src={item.imgs[0]} className="bannerImg" />
                      <div className="floating">
                        <div style={{ width: '100%' }} className="overHidden">
                          {item.title}
                        </div>
                      </div>
                    </div>
                  ))}
                </Carousel>
                <div className="checkouDots">
                  {data1.map((item, index) => (
                    <div key={item.id} onClick={e => this.carousel.goTo(index)} className={current === index ? "checkouDotsList" : "checkouDotsLists"} />
                  ))}
                </div>
                <div className="btn btn2" onClick={e => this.carousel.next()} />
              </div>
              {/* 列表 */}
              <div className="newList">
                {data2.map((item) => (
                  <a className="newListItem" key={item.id} onClick={() => {
                    this.setState({
                      colorList: [...colorList, item.id]
                    });
                    navgaitonto.open('/cocNewsDetail', `id=${item.id}`)
                  }}>
                    <div className="newListItemL">
                      <div className="dots" />
                      <div className="newListItemLText overHidden" style={{ color: colorList.indexOf(item.id) === -1 ? "#333" : "#666" }}>
                        {item.title}
                      </div>
                    </div>
                    {/* 日期 */}
                    <div className="newListItemR">
                      {dates.getMonthDay1(item.release_time)}
                    </div>
                  </a>
                ))}
              </div>
            </>
          )}
      </div>
    );
  }
}

export default App;
