import React, { Component } from 'react';
import { Button, message, Carousel, Pagination } from 'antd';

import TabBar from '../../../components/TabBar';
import Footer from '../../../components/Footer';
import HotNews from '../../../components/HotNews';
import Loading from '../../../components/Loading';
import PartyNewsCard from '../../../components/PartyNewsCard';
import HomeBanner from '../../../components/HomeBanner';
import NewListImg from '../../../components/NewListImg';
import MenberShow from '../../../components/MenberShow';
import Opportunity from '../../../components/Opportunity';
import dates from '../../../utils/time/time';
import nodataImg from '../../../asset/images/no_data.png';
import navgaitonTo from '../../../utils/navgaitonto';

// import Swiper from '../../../components/Swiper';
import request from '../../../utils/req';

import './index.less';

class App extends Component {
    state = {
        silderList: [],
        page: 1,
        total: 0,
        data: []
    }

    componentWillMount() {
        this.getShowSider();
        this.getDate();
    }

    getShowSider = () => {
        request('articleList', { top: 1, type: 7, num: 4 }, res => {
            if (res.code === 1000) {
                this.setState({
                    silderList: res.result.data
                });
            } else if (res.code === -1) {
                message.error("网络请求失败，请检查当前网络");
            }
        });
    }

    getDate = () => {
        const { page } = this.state;
        this.setState({
            loading: true
        });
        request('articleList', { type: 8, num: 6, p: page }, res => {
            if (res.code === 1000) {
                this.setState({
                    data: res.result.data,
                    total: res.result.total,
                    loading: false
                });
            } else if (res.code === -1) {
                message.error("网络请求失败，请检查当前网络");
                this.setState({
                    loading: false
                });
            }
        });
    }

    /* 标题 */
    renderTitle = (title, url) => {
        return (
            <div className='comTitle'>
                <div className="comTitleR">
                    {title}
                </div>
                <div className="comTitleL">
                    查看更多{'>'}
                </div>
            </div>
        );
    }
    render() {
        const { silderList, selectEd = 0, index = 3, page, total, loading, data } = this.state;
        const tabbarlist = ['商会简介', '商会大事件', '商会荣誉', '家乡风采', '关联商会'];
        return (<div className="homeShowindex">
            {/* 头部 */}
            <TabBar index="商会概况" />
            <div className="container">
                <div className='topBd'></div>
                <div className='content'>
                    {/* 左边 */}
                    <div className='sideNaw'>
                        {
                            tabbarlist.map((item, indexs) => (
                                <div onClick={() => {
                                    if (indexs === 1) {
                                        navgaitonTo.go('/CocEvent');
                                    } else if (indexs === 2) {
                                        navgaitonTo.go('/Cochonor');
                                    } else if (indexs === 3) {
                                        navgaitonTo.go('/HomeShow');
                                    } else if (indexs === 4) {
                                        navgaitonTo.go('/CocRlt');
                                    } else if (indexs === 0) {
                                        navgaitonTo.go('/CocDesc');
                                    }
                                }} className={index === indexs ? 'nawName navBarBar' : 'nawName'}>{item}</div>
                            ))
                        }
                    </div>
                    {/* 右边 */}
                    <div className='mainBox'>
                        {
                            data.length === 0 ?
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <img src={nodataImg} />
                                </div> : ""
                        }
                        {/* 轮播 */}
                        {
                            silderList.length > 0 && <div className="carouselBorder">
                                <Carousel afterChange={e => this.setState({ selectEd: e })} ref={e => this.carousel = e} autoplay dots={false}>
                                    {
                                        silderList.map((item, index) => (
                                            <div className='carousel' onClick={() => {
                                                console.log(`id=${item.id}`);
                                                let params = `id=${item.id}&type=家乡风采`;
                                                navgaitonTo.open('/cocNewsDetail', params);
                                            }}>
                                                {
                                                    item.imgs[0] ? <img src={item.imgs[0]} style={{ width: '100%', height: '100%' }} /> :
                                                        <video controls={false} src={item.video} />
                                                }
                                                {/* 阴影部分 */}
                                                <div className='carouselTxt'>
                                                    <p className='carouselTxt-1' >{item.title}</p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Carousel>
                                {/* 轮播点 */}
                                {silderList.length > 1 && <div className='carouselNaw'>
                                    {
                                        silderList.map((item, index) => (
                                            <span className={selectEd === index ? 'carouselNawDian selectDian' : "carouselNawDian"} onClick={e => this.carousel.goTo(index)} key={item.id}></span>
                                        ))
                                    }
                                </div>}
                            </div>
                        }

                        {/* 新闻列表 */}
                        <div>
                            {
                                loading ? <Loading /> : data.map(item => (
                                    <div className='news' onClick={() => navgaitonTo.open('/cocNewsDetail', `id=${item.id}&type=家乡风采`)}>
                                        {
                                            item.imgs[0] ? <img src={item.imgs[0]} className='bannerImg' /> : <video controls={false} className='bannerImg' src={item.video} />
                                        }

                                        <div className='newsTxt'>
                                            <div className='newsTxt-1'>
                                                <div className='twoOverHidden'>{item.title}</div>
                                                <div className='date'>{dates.getDate2(item.release_time)}</div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                        {
                            total > 6 ? <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 30, marginTop: 10 }}>
                                <Pagination showSizeChanger={false} pageSize={6} current={page} total={total} onChange={e => this.setState({ page: e }, this.getDate)} />
                            </div> : ""
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </div>)
    }
}

export default App;