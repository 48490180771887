import React, { Component } from 'react';
import './index.less';

class App extends Component {

    state = {
        tabsBat: 0,
        data: []
    }

    componentDidMount() {
        console.log(this.props);
        this.setState({
            data: this.props.data
        })
    }

    render() {
        const { tabsBat, data } = this.state;
        return (
            <div className="tabsbox">
                <div className="tabs">
                    {data.map((item, index) => (
                        <div
                            key={item}
                            // eslint-disable-next-line
                            className={tabsBat === index ? 'selctTabsBat tabsBat' : 'selctTabsBat'}
                            onClick={e => {
                                // eslint-disable-next-line
                                if (tabsBat !== index) {
                                    this.props.onChange(index);
                                }
                                this.setState({ tabsBat: index });
                            }}>{item}</div>
                    ))}
                </div>
            </div >)
    }
}

export default App;