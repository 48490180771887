import React, { Component } from "react";
import { Popover, message } from "antd";
import TabBar from "../../../components/TabBar";
import Footer from "../../../components/Footer";
import RepostiTitons from "../../../components/repostiTitons";
import nodataImg from '../../../asset/images/no_data.png';
import "./index.less";
import request from "../../../utils/req";
import navgaitonto from "../../../utils/navgaitonto";

class App extends Component {
  state = {
    data: {}
  };

  componentDidMount() {
    this.getDate(this.props.location.query.id);
  }

  /* 标题 */
  renderTitle = (title, type) => {
    return (
      <div className="comTitle">
        <div className="comTitleR">{title}</div>
        {type && <div className="comTitleL">查看更多{">"}</div>}
      </div>
    );
  };

  getDate = (id) => {
    request('memberInfo', { id }, res => {
      if (res.code === 1000) {
        this.setState({
          data: res.result
        });
        if (res.result.company?.name) {
          request('getQrcode', { page_url: 'opportunity/pages/companyDetails/index', scene: `id=${res.result.company?.id}`, }, ress => {
            if (ress.code === 1000) {
              this.setState({
                qrImg: ress.result
              });
            }
          });
        }
      } else {
        message.error("网络请求失败,请检查当前网络")
      }
    })
  }

  //  `/pages/vein/card/card?id=${this.state.card_data.id}&typesgo=${this.$router.params.typesgo}`

  renderSelectSession = () => {
    const { showSelectSession, session, sessionList } = this.state;

    return (
      <div
        onClick={() => this.setState({ showSelectSession: false })}
        className="memberListIndex"
      >
        <div className="title">届数</div>
        <div className="sessionList">
          {sessionList.map((item, index) => (
            <div
              onClick={() => this.setState({ session: index })}
              key={item}
              className={
                session == index
                  ? "sessionitem overHidden sessionitemse"
                  : "overHidden sessionitem"
              }
            >
              第{item}届
            </div>
          ))}
        </div>
      </div>
    );
  };

  render() {
    const { data, visbledPhoto, urls, params, qrImg } = this.state;
    return (
      <div className="memberIndex">
        <RepostiTitons isMember={true} visbled={visbledPhoto} url={urls} params={params} onChange={e => this.setState({ visbledPhoto: false })} />
        {/* 头部 */}
        <TabBar index="商会会员" />
        {/* 横线 */}
        <div className="container" >
          {/* 横线 */}
          <div className="tabBorder" />
          <div style={{ marginTop: 20, fontSize: 18, color: '#333' }}><span style={{ cursor: 'pointer' }} onClick={() => navgaitonto.go('/member')}>商会会员</span>&gt;会员详情</div>
          {/* 内容 */}
          <div className='topCard'>
            <div className="leftCardInfo">
              <img src={data.avatar} className="photo" />
              {/* 信息区域 */}
              <div className="infoBody1">
                <div className="name overHidden">{data.name}</div>
                {
                  data.native_place && <div className="cPostions overHidden">
                    {data.native_place}
                  </div>
                }
                {
                  data.company?.name && <div className="cPostions overHidden">
                    {data.company?.name}
                  </div>
                }
                {
                  data.company?.position && <div className="cPostions overHidden">
                    {data.company?.position}
                  </div>
                }

              </div>
              {/* 信息区域 */}
              <div className="infoBody2">
                {
                  data.position?.name && <div className="cPostions overHidden">
                    {data.coc_name}{data.position.name}
                  </div>
                }
                {
                  data.world_tag && <div className="cPostions overHidden">
                    {data.world_tag.join(',')}
                  </div>
                }
                <div className="moreBtn"
                  style={{
                    backgroundColor: data.card_id ? "#B80011" : "#DDD"
                  }}
                  onClick={e => {
                    if (data.card_id) {
                      this.setState({
                        urls: 'pages/vein/card/card',
                        params: `id=${this.state.data.card_id}`,
                        visbledPhoto: true
                      });
                    }
                  }}>
                  点击了解更多
                </div>
              </div>
            </div>
            {/* 个人简介 */}
            <div>
              {/* 个人简介 */}
              {
                data.describe && <div className="descCard">
                  <div className="title">
                    个人简介
                </div>
                  <div className="desc">
                    {data.describe}
                  </div>
                </div> || <div className="descCard">
                  <div className="title">
                    个人简介
                  </div>
                  <img src={nodataImg} style={{ display: 'block', margin: "0 auto" }} />
                </div>
              }

              {/* 公司信息 */}
              {
                data.company?.name && <div className="companyInfo">
                  <div className="title">
                    公司基本信息
                </div>
                  <div className="companyName overHidden">
                    公司名称：{data.company?.name}
                  </div>
                  {
                    data.company?.industry && data.company?.industry.length && <div className="companyName overHidden">
                      经营类目：{data.company?.industry.join(',')}
                    </div> || ''
                  }
                  {
                    data.company?.address && <div className="companyName overHidden">
                      公司地址：{data.company?.address}
                    </div> || ''
                  }
                  {
                    data.company?.phone && <div className="companyName overHidden">
                      公司电话： {data.company?.phone}
                    </div> || ''
                  }

                  <div className="photo">
                    <img src={qrImg} className="photos" />
                    <div className="texts">
                      扫一扫了解公司更多信息
                  </div>
                  </div>
                </div> || <div className="companyInfo">
                  <div className="title">
                    公司基本信息
                   </div>
                  <img src={nodataImg} style={{ display: 'block', margin: "0 auto" }} />
                </div>
              }
            </div>
          </div>

          {/* 公司简介 */}
          {
            data.company?.describe && <div className="companyDesc">
              <div className="title">
                公司简介
            </div>
              <div dangerouslySetInnerHTML={{ __html: data.company.describe }}></div>
            </div>
          }

          {/* 关联公司 */}
          {
            data.cardList && data.cardList?.length !== 0 && <div className="relationCompany">
              {/* 关联公司 */}
              <div className="title">
                关联公司
            </div>
              {
                data.cardList.map((item, index) => (
                  <div className="cardLists" key={item.id} onClick={e => this.setState({
                    urls: 'opportunity/pages/companyDetails/index',
                    params: `id=${item.company_id}`,
                    visbledPhoto: true
                  })}>
                    <div style={{ display: 'flex' }}>
                      <img src={item.avatar} className="photo" />
                      <div className="leftCard">
                        <div className="companyName overHidden">
                          {item.company_name}
                        </div>
                        {
                          item.company_industry && item.company_industry.length !== 0 && <div className="positions overHidden">
                            经营类目：{item.company_industry.join('/')}
                          </div>
                        }

                        {
                          item.company_position && <div className="positions overHidden">
                            公司职务：{item.company_position}
                          </div>
                        }
                      </div>
                    </div>
                  </div>))
              }
            </div>
          }
        </div>
        <Footer />
      </div >
    );
  }
}

export default App;
