import React, { Component } from "react";
import { Button, message, Carousel } from "antd";
import Loading from "../Loading";

import request from "../../utils/req";
import navgaitonto from "../../utils/navgaitonto";
import dates from "../../utils/time/time";

import "./index.less";

class App extends Component {
    state = {
        loading: false,
        data2: [],
        data1: [],
        current: 0
    };

    componentDidMount() {
        this.setState({
            loading: true,
        });
        request("articleList", { num: 10, cate_id: this.props.data.id }, (res) => {
            if (res.code === 1000) {
                const data1 = [];
                const data2 = [];
                res.result.data.map((item, index) => {
                    if (index > 5) {
                        data1.push(item);
                    } else {
                        data2.push(item);
                    }
                });
                this.setState({
                    data1,
                    data2,
                    loading: false,
                });
            } else if (res.code === -1) {
                this.setState({
                    loading: false,
                });
                message.error("网络请求失败，请检查当前网络");
            } else {
                this.setState({
                    loading: false,
                });
            }
        });
    }

    render() {
        const { loading, data2, data1, colorList = [] } = this.state;
        return (
            <div className="newsBox">
                {
                    loading ? (
                        <Loading />
                    ) :
                        <div className="newListse" >
                            {data2.map((item) => (
                                <div className="newListItem" key={item.id} onClick={() => {
                                    this.setState({
                                        colorList: [...colorList, item.id]
                                    });
                                    navgaitonto.open(this.props.url ? '/cocNewsDetail' : '/partyNewsDetail', `id=${item.id}`)
                                }}>
                                    <div className="newListItemL">
                                        <div className="dots" />
                                        <div className="newListItemLText overHidden" style={{ color: colorList.indexOf(item.id) === -1 ? "#333" : "#666" }}>
                                            {item.title}
                                        </div>
                                    </div>
                                    {/* 日期 */}
                                    <div className="newListItemR">
                                        {dates.getMonthDay1(item.release_time)}
                                    </div>
                                </div>
                            ))}
                        </div>
                }
                <div className="newsimgList">
                    {
                        data1.map(item => (
                            <div key={item.id} className="newsimgListBox" onClick={() => {
                                this.setState({
                                    colorList: [...colorList, item.id]
                                });
                                navgaitonto.open(this.props.url ? '/cocNewsDetail' : '/partyNewsDetail', `id=${item.id}`)
                            }}>
                                <div className="photo" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div className="cocNewsBgc" style={{ backgroundImage: `url(${item.imgs[0]})`, paddingBottom: '100%' }}></div>
                                    {/* <img src={item.imgs[0]} style={{ maxWidth: "100%", maxHeight: '100%' }} /> */}
                                </div>
                                <div className="title threeOverHidden" style={{ color: colorList.indexOf(item.id) === -1 ? "#333" : "#666" }} >
                                    {item.title}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );

    }

}

export default App;
