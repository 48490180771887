import React, { Component } from "react";
import dates from "../../utils/time/time";
import navgaitonto from "../../utils/navgaitonto";

import "./index.less";

class App extends Component {
  state = {
    data: {}
  }
  componentDidMount() {
    console.log(this.props.data);
    this.setState({
      data: this.props.data
    });
  }

  rennderGoTime(start, newDate, end) {
    console.log(dates.dateDay(start));
    console.log(dates.dateDay(newDate));
    if (start < newDate || dates.dateDay(newDate) === dates.dateDay(start)) {
      if (end > newDate || dates.dateDay(newDate) === dates.dateDay(start)) {
        return "进行中";
      } else {
        return "已完成";
      }
    } else {
      return "报名中"
    }
  }


  render() {
    const { data } = this.props;
    return (
      <div className="actCardList" onClick={() => navgaitonto.open('/ActDetail', `id=${data.id}`)}>
        <div>
          <div className="photo" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className="cocNewsBgc" style={{ backgroundImage: `url(${data?.imgs[0]})`, paddingBottom: '52%' }}></div>
          </div>

          <div className="title twoOverHidden">
            {data.title}
          </div>
        </div>
        <div className="bottoms">
          <div>
            {this.rennderGoTime(data.start_time, Date.parse(new Date()) / 1000, data.end_time)}
          </div>
          <div className="cityName overHidden">
            {data.address}
          </div>
        </div>
      </div>
    );
  }
}

export default App;
