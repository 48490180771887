import React, { Component } from 'react';
import { Button, message, Pagination } from 'antd';

import TabBar from '../../../components/TabBar';
import Footer from '../../../components/Footer';
import nodataImg from '../../../asset/images/no_data.png';
// import Swiper from '../../../components/Swiper';
import Loading from '../../../components/Loading';
import HotNews from '../../../components/HotNews';
import PartyNewsCard from '../../../components/PartyNewsCard';
import HomeBanner from '../../../components/HomeBanner';
import NewListImg from '../../../components/NewListImg';
import MenberShow from '../../../components/MenberShow';
import Opportunity from '../../../components/Opportunity';
import RepostiTitons from '../../../components/repostiTitons';
import dates from '../../../utils/time/time';
// import Swiper from '../../../components/Swiper';
import request from '../../../utils/req';
import navgaitonTo from '../../../utils/navgaitonto';

import './index.less';

class App extends Component {
    state = {
        data: [],
        page: 1,
        total: 0
    }

    componentWillMount() {
        this.getDate();
    }

    getDate = () => {
        this.setState({
            loding: true
        });
        const { page } = this.state;
        request('getBindCocList', { num: 8, p: page }, res => {
            if (res.code === 1000) {
                this.setState({
                    data: res.result.data,
                    total: res.result.total,
                    loading: false
                });
            } else if (res.code === -1) {
                this.setState({
                    loding: false
                });
                message.error("网络请求失败，请检查当前网络");
            }
        });
    }

    /* 标题 */
    renderTitle = (title, url) => {
        return (
            <div className='comTitle'>
                <div className="comTitleR">
                    {title}
                </div>
                <div className="comTitleL">
                    查看更多{'>'}
                </div>
            </div>
        );
    }

    render() {
        const { data, page, total, loading, visbledPhoto, params } = this.state;
        const tabbarlist = ['商会简介', '商会大事件', '商会荣誉', '家乡风采', '关联商会'];

        return (<div className="cocRlt">
            <RepostiTitons isMember={true} visbled={visbledPhoto} url='pages/tabbarDump/coc/index' params={params} onChange={e => this.setState({ visbledPhoto: false })} />

            {/* 头部 */}
            <TabBar index="商会概况" />
            <div className="container">
                <div className='topBd'></div>
                <div className='content'>
                    {/* 左边 */}
                    <div className='sideNaw'>
                        {
                            tabbarlist.map((item, indexs) => (
                                <div onClick={() => {
                                    if (indexs === 1) {
                                        navgaitonTo.go('/CocEvent');
                                    } else if (indexs === 2) {
                                        navgaitonTo.go('/Cochonor');
                                    } else if (indexs === 3) {
                                        navgaitonTo.go('/HomeShow');
                                    } else if (indexs === 4) {
                                        navgaitonTo.go('/CocRlt');
                                    } else if (indexs === 0) {
                                        navgaitonTo.go('/CocDesc');
                                    }
                                }} className={indexs === 4 ? 'nawName navBarBar' : 'nawName'}>{item}</div>
                            ))
                        }
                    </div>
                    {/* 右边内容 */}
                    <div>
                        <div className='contentBoxs'>
                            {/* 商会列表 */}
                            {
                                loading ? <Loading /> : data.length === 0 ? <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <img src={nodataImg} />
                                </div> : data.map(item => (
                                    <div className="cocCard" onClick={() => this.setState({ params: `id=${item.id}` }, () => this.setState({ visbledPhoto: true }))} key={item.id}>
                                        {/* 头像 */}
                                        <img src={item.logo} className="photo" />
                                        {/* 内容区域 */}
                                        <div className="bodyTainer">
                                            <div className="cocName overHidden">
                                                {item.name}
                                            </div>
                                            {
                                                dates.dateDay(item.create_time) && <div className="texts overHidden">
                                                    建会时间：{dates.dateDay(item.create_time)}
                                                </div>
                                            }
                                            {
                                                item.address && <div className="texts overHidden">
                                                    商会地址：{item.address}
                                                </div>
                                            }
                                            {
                                                item.master_name && <div className="texts overHidden">
                                                    商会会长：{item.master_name}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        {
                            total > 8 && <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 30, marginTop: 10 }}>
                                <Pagination showSizeChanger={false} pageSize={8} current={page} total={total} onChange={e => this.setState({ page: e }, this.getDate)} />
                            </div>
                        }
                    </div>
                </div>

            </div>
            <Footer />
        </div>)
    }
}

export default App;