import React, { Component } from "react";
import { Pagination, message, Input, Popover } from "antd";
import TabBar from "../../../components/TabBar";
import Footer from "../../../components/Footer";
import Opportunityitem from "../../../components/Opportunityitem";
import Businessitem from "../components/businessitem";
import BannerImg from "../../../components/BannerImg";
import dynamicImg from '../../../asset/images/dynamic.png';
import navBarBusiness from '../../../asset/images/navBarBusiness.png';
import recomBsiImg from '../../../asset/images/recomBsi.png';
import companyBgc from '../../../asset/images/companyBgc.png';
import companyLogo from '../../../asset/images/companyLogo.png';
import RepostiTitons from "../../../components/repostiTitons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import dates from "../../../utils/time/time";

import { SearchOutlined, DownOutlined } from '@ant-design/icons';
import "./index.less";
import request from "../../../utils/req";
import navgaitonto from "../../../utils/navgaitonto";
class App extends Component {
    state = {
        hotBoCateList: [],
        moreBtnText: "更多",
        data1: [],
        data2: [],
        data3: [],
        data4: [],
        data: [],
        companyList: []
    };


    componentDidMount() {
        this.getHotBoCateList();
        this.getBisList(0);

        this.getBisList(1);
        this.getBisList(2);
        this.getBisList(3);
        this.getBisList(4);

        this.getCocCompanyList();
    }

    /* 标题 */
    renderTitle = (title, url) => {
        return (
            <div className="comTitle">
                <div className="comTitleR">{title}</div>
                <div className="comTitleL">查看更多</div>
            </div>
        );
    };


    getBisList = (type) => {
        request('boList', { num: type === 0 ? 10 : 6, type: type }, res => {
            if (res.code === 1000) {
                if (type === 1) {
                    this.setState({
                        data1: res.result.data,
                        total1: res.result.total
                    });
                } else if (type === 2) {
                    this.setState({
                        data2: res.result.data,
                        total2: res.result.total
                    });
                } else if (type === 3) {
                    this.setState({
                        data3: res.result.data,
                        total3: res.result.total
                    });
                } else if (type === 4) {
                    this.setState({
                        data4: res.result.data,
                        total4: res.result.total
                    });
                } else {
                    this.setState({
                        data: res.result.data
                    });
                }
            } else {
                message.error("网络请求失败，请检查当前网络");
            }
        });
    }

    getHotBoCateList = () => {
        request('getSysCategoryList', {}, res => {
            if (res.code === 1000) {
                this.setState({
                    hotBoCateList: res.result
                });
            } else {
            }
        })
    }

    getCocCompanyList = () => {
        request('getCocCompanyList', { num: 6 }, res => {
            if (res.code === 1000) {
                this.setState({
                    companyList: res.result.data
                });
            }
        });
    }

    renderPopover = (List) => {
        return <div className="hotBoCateList">
            {List.map(item => (
                <div className="leftItemCard" key={item.id} onClick={() => navgaitonto.open('/BusinessList', `id=${item.id}&name=${item.name}`)}>
                    {item.name}
                </div>
            ))}

        </div>
    }
    render() {
        const {
            hotBoCateList,
            moreBtnText,
            data1,
            data2,
            data3,
            data4,
            data,
            title = "",
            total1,
            total2,
            total3,
            total4,
            companyList,
            visbledPhoto,
            params = false
        } = this.state;
        const settings = {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "60px",
            slidesToShow: data.length > 3 ? 3 : data.length,
            speed: 500,
            autoplay: true,
        };
        let tisee = title;
        return (
            <div className="businessIndex">
                <RepostiTitons isMember={true} par visbled={visbledPhoto} params={params} url='opportunity/pages/companyDetails/index' onChange={e => this.setState({ visbledPhoto: false })} />

                {/* 头部 */}
                <TabBar index="商会商机" />
                {/* 轮播图 */}
                <BannerImg />
                <div className="container">
                    {/* 商机动态 */}
                    <img src={dynamicImg} className="dynamic" />
                    {/* 商会筛选 */}
                    <div className='searchCard'>
                        {/* 搜索框 */}
                        <div className="serchInput">
                            <Input onPressEnter={e => {
                                if (tisee.length !== 0) {
                                    navgaitonto.open('/BusinessList', `title=${tisee}`);
                                }
                            }} onChange={e => this.setState({ title: e.target.value }, () => console.log(tisee))} style={{ borderColor: "#B80011" }} placeholder="请输入商机关键词" prefix={<SearchOutlined style={{ color: '#ccc', fontSize: 18 }} />} />
                            <div className='seacrchBtn' onClick={e => {
                                if (tisee.length !== 0) {
                                    navgaitonto.open('/BusinessList', `title=${tisee}`);
                                }
                            }}>
                                搜索
                            </div>
                        </div>
                        {/* 行业类目 */}
                        <div className="boCateCard">
                            <div className="leftItem">
                                {
                                    hotBoCateList.map((item, index) => (
                                        index < 16 || moreBtnText == "收起" ?
                                            <Popover content={this.renderPopover(item.children)} key={item.id} placement="bottomRight">
                                                <div className="leftItemCard" onClick={() => navgaitonto.open('/BusinessList', `id=${item.id}&name=${item.name}`)}>
                                                    {item.name}
                                                </div>
                                            </Popover>
                                            : ""
                                    ))
                                }
                            </div>
                            <div className="rightMoreBtn" onClick={() => this.setState({ moreBtnText: moreBtnText === "更多" ? "收起" : "更多" })}>
                                {moreBtnText}<DownOutlined style={{ marginLeft: 10, transform: moreBtnText === "更多" ? '' : 'rotate(180deg)' }} />
                            </div>
                        </div>
                    </div>
                    {/* 推荐商机 */}
                    <img src={recomBsiImg} />
                    <div className="activitySwiper">
                        <Slider {...settings}>
                            {data.length !== 0 &&
                                data.map((item) => {
                                    return (
                                        <Opportunityitem key={item.id} type={item.type} data={item} />
                                    );
                                })}
                        </Slider>
                    </div>
                    {/* 商机图片 */}
                    <img src={navBarBusiness} />
                    {/* 采购 */}
                    <div className="businessbox">
                        <Businessitem text="采购" total={total2} />
                        <div className="right">
                            {data2.map(item => (
                                <Opportunityitem key={item.id} type={2} data={item} />
                            ))}
                        </div>
                    </div>
                    {/* 需求 */}
                    <div className="businessbox">
                        <Businessitem text="需求" total={total3} />
                        <div className="right">
                            {data3.map(item => (
                                <Opportunityitem key={item.id} type={3} data={item} />
                            ))}
                        </div>
                    </div>
                    {/* 供应 */}
                    <div className="businessbox">
                        <Businessitem text="供应" total={total1} />
                        <div className="right">
                            {data1.map(item => (
                                <Opportunityitem key={item.id} type={1} data={item} />
                            ))}
                        </div>
                    </div>
                    {/* 招标 */}
                    {data4.length !== 0 && <div className="businessbox">
                        <Businessitem text="招标" total={total4} />
                        <div className="right">
                            {data4.map(item => (
                                <Opportunityitem key={item.id} type={4} data={item} />
                            ))}
                        </div>
                    </div>}
                    {/* 商机图片 */}
                    {
                        companyList.length !== 0 && <img src={companyBgc} />
                    }
                    {
                        companyList.length !== 0 &&
                        <div className="cocCardContainer">
                            {
                                companyList.map(item => (
                                    <div className="cocCard" key={item.id} onClick={() => this.setState({ visbledPhoto: true, params: `id=${item.company_id}` })}>
                                        {/* 头像 */}
                                        <img className="photo" src={item.company_logo || companyLogo} />
                                        {/* 内容区域 */}
                                        <div className="bodyTainer">
                                            <div className="cocName overHidden">
                                                {item.company_name}
                                            </div>
                                            <div className="texts overHidden">
                                                {item.industry && item.industry.join('/')}
                                            </div>
                                            <div className="site overHidden">
                                                {item.company_city}{item.company_district && `/${item.company_district}`}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>}
                    {
                        companyList.length !== 0 &&
                        <div className="lookMore" onClick={() => navgaitonto.open('/CompanyList')}>
                            查看更多{'>>'}
                        </div>
                    }
                </div>
                <Footer />
            </div>
        );
    }
}

export default App;
