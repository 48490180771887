import React, { Component } from "react";
import "./index.less";
import navgaitonto from '../../utils/navgaitonto';
class App extends Component {
  state = {
    loading: false,
    item: {},
  };


  render() {
    const { data } = this.props;
    return (
      <div className="MenberCards" onClick={() => navgaitonto.open('/MemberInfo', `id=${data.id}`)}>
        <div className="photo" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src={data.avatar} style={{ width: "100%", height: '100%' }} />
        </div>
        <div className="name overHidden">{data.name}</div>
        {data.position?.name && (
          <div className="position overHidden">
            {data.position.name}
            {/* aweae */}
          </div>
        )}
        {data.company?.company_name && (
          <div className="position overHidden">
            {data.company?.company_name}
          </div>
        )}
      </div>
    );
  }
}

export default App;
