import React, { Component } from 'react';
import './index.less';
import { Button, message, Carousel } from "antd";

import request from "../../utils/req";
import navgaitonto from "../../utils/navgaitonto";
import Loading from "../Loading";


class App extends Component {
    state = {
        loading: false,
        data: [],
    };
    componentDidMount() {
        this.setState({
            loading: true,
        });
        request('articleCateList', { num: 1, type: 1 }, resCate => {
            if (resCate.code === 1000) {
                let cateId = 0;
                if (resCate.result?.data?.length > 0) {
                    cateId = resCate.result?.data[0]?.id
                }
                request("articleList", { num: 5, cate_id: cateId, top: 1 }, (res) => {
                    if (res.code === 1000) {
                        this.setState({
                            data: res.result.data,
                            loading: false,
                        });
                    } else if (res.code === -1) {
                        this.setState({
                            loading: false,
                        });
                        message.error("网络请求失败，请检查当前网络");
                    } else {
                        this.setState({
                            loading: false,
                        });
                    }
                });
            } else {
                message.error("网络请求失败，请检查当前网络");
                this.setState({
                    loading: false,
                });
            }
        });
    }

    render() {
        const { loading, data } = this.state;
        return (
            <div className="cocListImg">
                {
                    loading ? <Loading /> : data.map(item => (
                        <div className="cocListItem" key={item.id} onClick={() => navgaitonto.open('/cocNewsDetail', `id=${item.id}`)}>
                            
                            <img src={item.imgs[0]} />
                            <div className="flating">
                                <div style={{ width: '100%', textAlign: 'center' }} className="overHidden">
                                    {item.title}
                                </div>
                            </div>
                        </div>
                    ))
                }

            </div>)
    }
}

export default App;