import React, { Component } from "react";
import "./index.less";
import navgaitonto from '../../../../utils/navgaitonto';
import request from "../../../../utils/req";

class App extends Component {
    state = {
        hotBoCateList: [],
        moreBtnText: "更多"
    };

    componentDidMount() {
        this.getHotBoCateList();
        this.getQrcode('pages/tabbarDump/bo/index');
    }

    getQrcode = (url, p) => {
        if (!this.state.img) {
            this.setState({ loading: true });
            request('getQrcode', { page_url: url }, res => {
                if (res.code === 1000) {
                    this.setState({ loading: false, img: res.result });
                } else {
                    this.setState({ loading: false });
                }
            });
        }
    }


    getHotBoCateList = () => {
        const { text } = this.props;
        let type = 0;
        if (text === "需求") {
            type = 3;
        }
        if (text === "供应") {
            type = 1;
        }
        if (text === "采购") {
            type = 2;
        }
        if (text === "招标") {
            type = 4;
        }
        request('getHotBoCateList', { type: type, }, res => {
            if (res.code === 1000) {
                this.setState({
                    hotBoCateList: res.result
                });
            }
        })
    }

    render() {
        const { text, total } = this.props;
        const { hotBoCateList } = this.state;
        let type = 0;
        if (text === "需求") {
            type = 3;
        }
        if (text === "供应") {
            type = 1;
        }
        if (text === "采购") {
            type = 2;
        }
        if (text === "招标") {
            type = 4;
        }
        return (
            <div className="businessleft">

                <div className="title">
                    {text}总数
                 </div>
                <div className="title">
                    {total}单
                   </div>
                <div className="borderF" />
                {/* 查看更多 */}
                <div className="morebtn" onClick={() => navgaitonto.open('/BusinessList', `type=${type}`)}>
                    查看更多{'>'}
                </div>
                {/* 下半部 */}
                <div className="bottom">
                    {/* 热门采购 */}
                    <div className="hotTitle">
                        热门{text}
                    </div>
                    {/* 盒子部分 */}
                    <div className="cardCardBoxs">
                        {
                            hotBoCateList.map(item => (
                                <div className="boxitem overHidden" key={item.id} onClick={() => navgaitonto.open('/BusinessList', `id=${item.id}&name=${item.name}&type=${type}`)}>
                                    {item.name}
                                </div>
                            ))
                        }
                    </div>
                    {/* 二维码 */}
                    <div className="photos">
                        <img src={this.state.img} className="photo" />
                        扫码立即发布
                  </div>
                </div>
            </div>

        );
    }
}

export default App;
