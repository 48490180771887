import React, { Component } from "react";
import { Pagination } from "antd";
import TabBar from "../../../components/TabBar";
import Footer from "../../../components/Footer";
import ActCardList from "../../../components/ActCardList";
import TabPane from "../../../components/TabPane";
import Loading from "../../../components/Loading";
import dates from "../../../utils/time/time";
import request from "../../../utils/req";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import actImgTitle from "../../../asset/images/act.png";
import speciallyAct from "../../../asset/images/speciallyAct.png";
import "./index.less";
import navgaitonto from "../../../utils/navgaitonto";

class App extends Component {
  state = {
    page: 1,
    loading: false,
    data: [],
    topList: [],
    total: 0,
    state: 0,
  };
  componentWillMount() {
    this.getTopList();
  }
  componentDidMount() {
    this.getDate();
  }
  componentDidUpdate() { }

  getDate = () => {
    const { page, loading, state } = this.state;
    this.setState({
      loading: true,
    });
    request("actList", { type: 0, num: 12, p: page, state: state }, (res) => {
      if (res.code === 1000) {
        this.setState({
          loading: false,
          data: res.result.data,
          total: res.result.total,
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  };

  getTopList() {
    request("actList", { type: 0, num: 5, p: 1, state: 0, top: 1 }, (res) => {
      if (res.code === 1000) {
        this.setState({
          topList: res.result.data,
        });
      }
    });
  }
  /* 标题 */
  renderTitle = (title, url) => {
    return (
      <div className="comTitle">
        <div className="comTitleR">{title}</div>
        <div className="comTitleL">查看更多</div>
      </div>
    );
  };
  rennderGoTime(start, newDate, end) {
    console.log(dates.dateDay(start));
    console.log(dates.dateDay(newDate));
    if (start < newDate || dates.dateDay(newDate) === dates.dateDay(start)) {
      if (end > newDate || dates.dateDay(newDate) === dates.dateDay(start)) {
        return "进行中";
      } else {
        return "已完成";
      }
    } else {
      return "报名中"
    }
  }
  render() {
    const { data, total, page, loading, topList } = this.state;
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "60px",
      slidesToShow: data.length > 3 ? 3 : data.length,
      speed: 500,
      autoplay: true,
    };
    return (
      <div className="actIndex">
        {/* 头部 */}
        <TabBar index="活动会议" />
        <div className="container">
          {/* 横线 */}
          <div className="tabBorder" />
          <img src={speciallyAct} className="actImgTitle" />
          {/* 活动 */}
          <div className="activitySwiper">
            {
              data.length < 3 ? data.map((item) => {
                return (
                  <div style={{ marginRight: 20, display: 'inline-block' }} className="activitySwiperItem" key={item.id} onClick={() => navgaitonto.open('/ActDetail', `id=${item.id}`)}>
                    <div className="actLoading" style={{
                      background: this.rennderGoTime(item.start_time, Date.parse(new Date()) / 1000, item.end_time) === "已完成" ? '#FFB100' : "B80011"
                    }}>
                      {this.rennderGoTime(item.start_time, Date.parse(new Date()) / 1000, item.end_time)}
                    </div>
                    <div className="pic">
                      <div className="cocNewsBgc" style={{ backgroundImage: `url(${item.imgs[0]})`, paddingBottom: '68%' }}></div>
                    </div>
                    <div className="content">
                      <p className="tit">{item.title}</p>
                      <div className="infos">
                        <div className="con">
                          <span>
                            报名截止：
                              {dates.getTime1(item.apply_deadline_time, 1)}
                          </span>
                          <span>报名人数：{item.join_num}人</span>
                        </div>
                        <div className="con">
                          <span>活动费用：{item.price}元/人</span>
                          <span className="address">
                            活动地址：{item.address}
                          </span>
                        </div>
                      </div>
                      <span className="more">查看详情&gt;&gt;</span>
                    </div>
                  </div>
                );
              }) : <Slider {...settings}>
                  {data.length !== 0 &&
                    data.map((item) => {
                      return (
                        <div className="activitySwiperItem" key={item.id}
                          onMouseDown={e => this.setState({
                            dateNow: Date.now()
                          })} onClick={(e) => {
                            let isgo = this.state.dateNow < Date.now() - 200;
                            if (!isgo) {
                              navgaitonto.open('/ActDetail', `id=${item.id}`);
                            }
                          }}>
                          <div className="actLoading" style={{
                            background: this.rennderGoTime(item.start_time, Date.parse(new Date()) / 1000, item.end_time) === "已完成" ? '#FFB100' : "B80011"
                          }}>
                            {this.rennderGoTime(item.start_time, Date.parse(new Date()) / 1000, item.end_time)}
                          </div>
                          <div className="pic">
                            <div className="cocNewsBgc" style={{ backgroundImage: `url(${item.imgs[0]})`, paddingBottom: '68%' }}></div>
                          </div>
                          <div className="content">
                            <p className="tit">{item.title}</p>
                            <div className="infos">
                              <div className="con">
                                <span>
                                  报名截止：
                                {dates.getTime1(item.apply_deadline_time, 1)}
                                </span>
                                <span>报名人数：{item.join_num}人</span>
                              </div>
                              <div className="con">
                                <span>活动费用：{item.price}元/人</span>
                                <span className="address">
                                  活动地址：{item.address}
                                </span>
                              </div>
                            </div>
                            <span className="more">查看详情&gt;&gt;</span>
                          </div>
                        </div>
                      );
                    })}
                </Slider>
            }
          </div>
          <img src={actImgTitle} className="actImgTitle" />
          {/* 筛选活动 */}
          <div className="selectPages">
            <TabPane
              data={["全部", "进行中", "已完成"]}
              onChange={(e) =>
                this.setState({ page: 1, state: e }, () => this.getDate())
              }
            />
          </div>
          {/* 列表 */}
          <div className="actCards">
            {loading ? (
              <Loading />
            ) : (
                data.map((item) => <ActCardList key={item.id} data={item} />)
              )}
          </div>
          {total > 12 && <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 30,
            }}
          >
            <Pagination
              current={page}
              total={total}
              pageSize={12}
              onChange={(e) => this.setState({ page: e }, () => this.getDate())}
              showSizeChanger={false}
            />
          </div>}
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
