import React, { Component } from "react";
import "./index.less";
// 红条

class App extends Component {
  state = {
    tabsBat: 0,
    list: [],
    select: {}
  };
  componentDidMount() {
    this.setState({
      select: this.props.select
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      list: nextProps.tabbarList,
      select: nextProps.select
    });
  }
  render() {
    const { tabsBat, list, select } = this.state;
    console.log(select);
    return (
      <div className="redStripNav">
        <div className="navList">
          {list.map((item, index) => (
            <div
              key={item.id}
              className={Number(select.id) == Number(item.id) || select == Number(item.id) ? "selecItems" : "navItem"}
              onClick={(e) => {
                console.log(select.id, item.id);
                // eslint-disable-next-line
                if (select.id != item.id) {
                  this.props.onChange(item);
                }
              }}
            >
              {item.name}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default App;
