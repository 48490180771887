import React, { Component } from "react";
import { Pagination, message } from "antd";
import TabBar from "../../../components/TabBar";
import NewsNav from "../../../components/NewsNav";
import CocNewsItem from "../../../components/CocNewsItem";
import Footer from "../../../components/Footer";
import Loading from "../../../components/Loading";
import request from "../../../utils/req";
import "./index.less";
import navgaitonto from "../../../utils/navgaitonto";

class App extends Component {
  state = {
    listData: [],
    currentItem: {},
    page: 1,
    total: 0,
    loading: false,
  };
  componentWillMount() {
    console.log(this.props.location.pathname === "/partyNewsList");
    let type = 1;

    if (this.props.location.pathname === "/partyNewsList") {
      type = 3;
      this.setState({
        indexName: "党建中心"
      })
    }


    request("articleCateList", { type: type }, (res) => {
      if (res.code === 1000) {
        const result = res.result.data;
        let dats = result[0];
        if (this.props.location.query.cate) {
          result.map(item => {
            if (item.id === Number(this.props.location.query.cate)) {
              dats = item;
            }
          })
        }
        this.setState(
          {
            loading: false,
            listData: result,
            currentItem: dats,
          },
          () => {
            if (this.props.location.query.cate) {
              this.getList(dats);
            } else {
              if (result.length > 0 && result[0].id) {
                this.getList(result[0]);
              }
            }

          }
        );
      } else if (res.code === -1) {
        this.setState({
          loading: false,
        });
        message.error("网络请求失败，请检查当前网络");
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }
  getList = (item) => {
    const { page, currentItem } = this.state;
    this.setState({
      currentItem: item,
      articleList: []
    }, () => {
      if (item.id) {
        request(
          "articleList",
          { cate_id: item.id, p: page, num: 7 },
          (res) => {
            if (res.code === 1000) {
              this.setState({
                articleList: res.result.data,
                total: res.result.total,
              });
            } else if (res.code === -1) {
              this.setState({
                loading: false,
              });
              message.error("网络请求失败，请检查当前网络");
            } else {
              this.setState({
                loading: false,
              });
            }
          }
        );
      }
    })


  }
  goDetail(id) {
    console.log("列表页");
    // window.location.href = `/cocNewsDetail?id=${id}`;
    navgaitonto.open('/cocNewsDetail', `id=${id}`)
  }

  render() {
    const {
      listData = [],
      articleList = [],
      total,
      page,
      currentItem,
      loading,
      indexName
    } = this.state;
    return (
      <div className="newsList">
        {/* 头部 */}
        <TabBar index={indexName || "商会新闻"} />
        {/* 红条 */}
        < div className="redStrip"></div>
        <div className="container">
          <NewsNav
            tabbarList={listData}
            select={currentItem}
            onChange={(e) => this.setState({ page: 1 }, () => this.getList(e))}
          ></NewsNav>
          <div className="cocNewsItems">
            {loading ? (
              <Loading />
            ) : (
                articleList.map((item) => {
                  return (
                    <CocNewsItem
                      handClick={() => this.goDetail(item.id)}
                      data={item}
                      key={item.id}
                    ></CocNewsItem>
                  );
                })
              )}
            {articleList.length == 0 && (
              <img
                className="noDataImg"
                src={require("../../../asset/images/no_data.png")}
              ></img>
            )}

            {total > 7 && (
              <div className="paginationBox">
                <Pagination
                  current={page}
                  total={total}
                  pageSize={7}
                  onChange={(e) =>
                    this.setState({ page: e }, () => this.getList(currentItem))
                  }
                  showSizeChanger={false}
                />
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
