import React, { Component } from "react";
import { message, Carousel } from "antd";
import TabBar from "../../../components/TabBar";
import Footer from "../../../components/Footer";
import HotNews from "../../../components/HotNews";
import PartyNewsCard from "../../../components/PartyNewsCard";
import HomeBanner from "../../../components/HomeBanner";
import NewListImg from "../../../components/NewListImg";
import MenberShow from "../../../components/MenberShow";
import Opportunity from "../../../components/Opportunity";
import BannerImg from "../../../components/BannerImg";
import TabPane from "../../../components/TabPane";
import CompanyList from "../../../components/companyList";
import RepostiTitons from "../../../components/repostiTitons";
import request from "../../../utils/req";
import dates from "../../../utils/time/time";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.less";
import indexDescImg from '../../../asset/images/indexDesc.png';
import no_dataImg from '../../../asset/images/no_data.png';
import indexBgc from '../../../asset/images/indexBgc.png';
import navgaitonto from "../../../utils/navgaitonto";


function group(array, subGroupLength) {
  let index = 0;
  let newArray = [];
  while (index < array.length) {
    newArray.push(array.slice(index, index += subGroupLength));
  }
  return newArray;
}

class App extends Component {
  state = {
    cocInfo: {},
    page: 1,
    loading: false,
    data: [],
    total: 0,
    state: 0,
    cateList: [{ name: "推荐新闻" }],
    lianjieList: []
  };

  componentWillMount() {
    request("getCocInfo", {}, (res) => {
      if (res.code === 1000) {
        this.setState({
          cocInfo: res.result,
        });
      } else if (res.code === -1) {
        message.error("网络请求失败，请检查当前网络");
      }
    });
  }

  componentDidMount() {
    this.getCateList()
    this.getDate();
    this.getCocLinkList();
  }
  getDate = (type = 0) => {
    const { page, loading, state } = this.state;
    this.setState({
      loading: true,
    });
    request("actList", { type: type, num: 12, p: 1, state: state }, (res) => {
      if (res.code === 1000) {
        this.setState({
          loading: false,
          data: res.result.data,
          total: res.result.total,
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  };

  /* 标题 */
  renderTitle = (title, url, type = false) => {
    return (
      <div className="comTitle">
        <div className="comTitleR">{title}</div>
        {
          url && <div className="comTitleL" onClick={() => navgaitonto.go(url, type)}>查看更多{">"}</div>
        }

      </div>
    );
  };

  getCateList = () => {
    request("articleCateList", { num: 1, type: 1 }, (res) => {
      if (res.code === 1000) {
        if (res.result.data.length === 1) {
          this.setState({
            cateList: res.result.data
          });
        }
      }
    });
  }

  rennderGoTime(start, newDate, end) {
    console.log(dates.dateDay(start));
    console.log(dates.dateDay(newDate));
    if (start < newDate || dates.dateDay(newDate) === dates.dateDay(start)) {
      if (end > newDate || dates.dateDay(newDate) === dates.dateDay(start)) {
        return "进行中";
      } else {
        return "已完成";
      }
    } else {
      return "报名中"
    }
  }
  getCocLinkList = () => {
    // lianjieList.push([{ name: '123123', url: "baidu.com" }, { name: '123123', url: "123123123" }, { name: '123123', url: "123123123" }, { name: '123123', url: "123123123" }, { name: '123123', url: "123123123" }, { name: '123123', url: "123123123" }])
    // lianjieList.push([{ name: 'aasdasd', url: "http://baidu.com" }, { name: 'aasdasd', url: "123123123" }, { name: 'aasdasd', url: "123123123" }, { name: 'aasdasd', url: "aasdasd" }, { name: 'aasdasd', url: "123123123" }, { name: 'aasdasd', url: "123123123" }])
    // lianjieList.push([{ name: 'azsda', url: "123123123" }, { name: '123123', url: "123123123" }, { name: '123123', url: "123123123" }, { name: '123123', url: "123123123" }, { name: '123123', url: "123123123" }, { name: '123123', url: "123123123" }])
    // lianjieList.push([{ name: '123124444443', url: "123123123" }, { name: '123123', url: "123123123" }, { name: '123123', url: "123123123" }, { name: '123123', url: "123123123" }, { name: '123123', url: "123123123" }, { name: '123123', url: "123123123" }])
    // this.setState({
    //   lianjieList
    // })
    request('getCocLinkList', {}, res => {
      if (res.code === 1000) {
        // const nums = Math.ceil(res.result.length / 6);
        // for (let index = 0; index < nums; index++) {
        //   lianjieList.push([]);
        // }
        // res.result.map((item, index) => {

        // });
        this.setState({
          lianjieList: group(res.result, 6)
        });
      }
    });
  }
  render() {
    // console.log(this.props); this.props.history.push('');
    const { cocInfo, data, visbledPhoto, params, cateList, lianjieList, currents = 0 } = this.state;
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "60px",
      slidesToShow: data.length > 3 ? 3 : data.length,
      speed: 500,
      autoplay: true,
    };
    return (
      <div className="index">
        {/* 头部 */}
        <TabBar index="首页" />
        {/* 轮播图 */}
        <BannerImg />
        <RepostiTitons isMember={true} visbled={visbledPhoto} params={params} url='opportunity/pages/companyDetails/index' onChange={e => this.setState({ visbledPhoto: false })} />

        {/* 主体内容 */}
        <div className="container">
          {/* 商会简介 */}
          <div className="containerBody" style={{ paddingBottom: 25 }}>
            {/* 标题 */}
            {this.renderTitle("商会简介")}
            {/* 内容部分 */}
            <div className="descContainer">
              {/* 图片 */}
              <img src={cocInfo.desc_img || indexDescImg} className="descImg" />
              {/* 文字 */}
              <div className="descBox">
                <div dangerouslySetInnerHTML={{ __html: cocInfo.describe_str }} className="descBoxText oneOneOverHidden" />
                <div className="descBoxBtn" onClick={() => navgaitonto.go('/CocDesc')}>查看详情{">"}</div>
              </div>
            </div>
          </div>
          {/* 商会新闻 */}
          <div className="containerBody" style={{ marginTop: 25 }}>
            {/* 热点要闻 */}
            {this.renderTitle("热点要闻", '/cocNews')}
            {/* 内容部分 */}
            <HotNews />
            {/* ---- ---  --- -- */}
            {/* 推荐新闻 */}
            <div className="newsButtom">
              <div className="newsButtomL">
                {this.renderTitle(cateList[0].name, '/cocNews', cateList[0]?.id ? `cate=${cateList[0]?.id}` : false)}
                <NewListImg />
                {this.renderTitle("家乡风采", '/HomeShow')}
                <HomeBanner />
              </div>
              {/* 党建新闻 */}
              <PartyNewsCard />
            </div>
          </div>
          {/* 会员风采 */}
          <div className="containerBody" style={{ marginLeft: 5 }}>
            {/* 会员风采 */}
            {this.renderTitle("会员风采", '/member')}
            <MenberShow />
          </div>
          {/* 商会商机 */}
          <div className="containerBody">
            <Opportunity />
          </div>

          {/* 活动swiper */}
          <div className="containerBody">
            <div className="selectPages">
              <div className='comTitle'>
                <div className="comTitleR">
                  商会活动
                  <TabPane data={['全部', '进行中', '已完成']} onChange={e => this.setState({ state: e, data: [] }, () => this.getDate())} />
                </div>
                <div onClick={() => navgaitonto.go('/act')} className="comTitleL">
                  查看更多{'>'}
                </div>
              </div>
            </div>
            <div className="activitySwiper">
              {data.length === 0 && !this.state.loading ? <img src={no_dataImg} style={{ display: 'block', margin: '0 auto' }} /> : ""}
              {
                data.length < 3 ? data.map((item) => {
                  return (
                    <div style={{ marginRight: 20, display: 'inline-block' }} className="activitySwiperItem" key={item.id} onClick={() => navgaitonto.open('/ActDetail', `id=${item.id}`)}>
                      <div className="actLoading" style={{
                        background: this.rennderGoTime(item.start_time, Date.parse(new Date()) / 1000, item.end_time) === "已完成" ? '#FFB100' : "B80011"
                      }}>
                        {this.rennderGoTime(item.start_time, Date.parse(new Date()) / 1000, item.end_time)}
                      </div>
                      <img className="pic" src={item.imgs[0]}></img>
                      <div className="content">
                        <p className="tit">{item.title}</p>
                        <div className="infos">
                          <div className="con">
                            <span>
                              报名截止：
                              {dates.getTime1(item.apply_deadline_time, 1)}
                            </span>
                            <span>报名人数：{item.join_num}人</span>
                          </div>
                          <div className="con">
                            <span>活动费用：{item.price}元/人</span>
                            <span className="address">
                              活动地址：{item.address}
                            </span>
                          </div>
                        </div>
                        <span className="more">查看详情&gt;&gt;</span>
                      </div>
                    </div>
                  );
                }) : <Slider {...settings}>
                    {data.length !== 0 &&
                      data.map((item) => {
                        return (
                          // () => 
                          <div
                            className="activitySwiperItem"
                            key={item.id}
                            onMouseDown={e => this.setState({
                              dateNow: Date.now()
                            })}
                            onClick={(e) => {
                              let isgo = this.state.dateNow < Date.now() - 200;
                              if (!isgo) {
                                navgaitonto.open('/ActDetail', `id=${item.id}`)
                              }
                            }}>
                            <div className="actLoading" style={{
                              background: this.rennderGoTime(item.start_time, Date.parse(new Date()) / 1000, item.end_time) === "已完成" ? '#FFB100' : "B80011"
                            }}>
                              {this.rennderGoTime(item.start_time, Date.parse(new Date()) / 1000, item.end_time)}
                            </div>
                            <img className="pic" src={item.imgs[0]}></img>
                            <div className="content">
                              <p className="tit">{item.title}</p>
                              <div className="infos">
                                <div className="con">
                                  <span>
                                    报名截止：
                                {dates.getTime1(item.apply_deadline_time, 1)}
                                  </span>
                                  <span>报名人数：{item.join_num}人</span>
                                </div>
                                <div className="con">
                                  <span>活动费用：{item.price}元/人</span>
                                  <span className="address">
                                    活动地址：{item.address}
                                  </span>
                                </div>
                              </div>
                              <span className="more">查看详情&gt;&gt;</span>
                            </div>
                          </div>
                        );
                      })}
                  </Slider>
              }
            </div>
          </div>
          <div style={{ display: 'flex', marginTop: 40, marginBottom: 20, justifyContent: 'space-between', width: '100%' }}>
            <img src={indexBgc} style={{ width: '762px' }} />
            <CompanyList onClick={e => {
              this.setState({
                visbledPhoto: true,
                params: `id=${e}`
              });
            }} />
          </div>
          {/* 友情链接 */}
          <div className="relation">
            {/* 内容 */}
            {this.renderTitle("友情链接")}
            <div className="relationBanner">
              <div className="relationBannerBtn rotato" onClick={() => {
                if (lianjieList.length === 0) {
                  return
                }
                if (currents === 0) {
                  this.setState({ currents: lianjieList.length - 1 });
                  return
                }
                this.setState({ currents: currents - 1 });
              }} />
              {/* <Carousel ref={dom => this.carousel = dom} afterChange={e => this.setState({ current: e })} dots={false}> */}
              {
                lianjieList.map((items, idnex) => {
                  return idnex === currents && <div className="cocListbox">
                    {
                      items.map(item => (
                        <a href={item.url.indexOf('http') === -1 ? `http://${item.url}` : item.url} className="cocList overHidden" target="_blank" >{item.name}</a>
                      ))
                    }
                  </div>
                })
              }
              {/* </Carousel> */}
              <div className="relationBannerBtn rotato1" onClick={() => {
                if (currents === lianjieList.length - 1) {
                  this.setState({ currents: 0 });
                  return
                }
                this.setState({ currents: currents + 1 });
              }} />
            </div>
          </div>
        </div>
        <Footer />
      </div >
    );
  }
}

export default App;
