import React, { Component } from "react";
import "./index.less";
import navgaitonto from "../../utils/navgaitonto";
import request from "../../utils/req";

class App extends Component {
    state = {
        data1: []
    };
    componentDidMount() {
        request("articleList", { num: 3, cate_id: this.props.data.id }, (res) => {
            if (res.code === 1000) {
                this.setState({
                    data1: res.result.data,
                    loading: false,
                });
            } else if (res.code === -1) {
                this.setState({
                    loading: false,
                });
            } else {
                this.setState({
                    loading: false,
                });
            }
        });
    }
    /* 标题 */
    renderTitle = (title, cate) => {
        let params = false;
        if (cate) {
            params = `cate=${cate.cate.id}`;
        }
        return (
            <div className="comTitle">
                <div className="comTitleR">{title}</div>
                <div className="comTitleL" onClick={() => navgaitonto.open('/cocNewsList', params)}>查看更多{'>'}</div>
            </div>
        );
    };
    render() {
        const { data1 } = this.state;
        return (
            < div className="catesOne" >
                { this.renderTitle(this.props.data.name, data1[0])}
                < div className="newsCard" >
                    {/* 左 */}
                    < div className='left' >
                        {
                            data1[0] && <div className="newCard" onClick={() => navgaitonto.open('/cocNewsDetail', `id=${data1[0].id}`)}>
                                {/* 图片 */}
                                <div className="photo" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <img src={data1[0].imgs[0]} style={{ maxWidth: "100%", maxHeight: '100%' }} />
                                </div>
                                <div className="containers">
                                    <div className='title overHidden'>
                                        {data1[0].title}
                                    </div>
                                    <div dangerouslySetInnerHTML={{ __html: data1[0].content }} className="texts oneOneOverHidden" />
                                </div>
                            </div>
                        }

                        {
                            data1[1] && <div className="newCards" onClick={() => navgaitonto.open('/cocNewsDetail', `id=${data1[1].id}`)}>
                                <div className="title overHidden">
                                    {data1[1].title}
                                </div>
                                <div className="contarners">
                                    <div dangerouslySetInnerHTML={{ __html: data1[1].content }} className="texts eightOverHidden" />
                                    <div className="photos" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <img src={data1[1].imgs[0]} style={{ maxWidth: "100%", maxHeight: '100%' }} />
                                    </div>
                                </div>
                            </div>
                        }


                    </div >
                    {/* 右 */}
                    {
                        data1[2] &&
                        <div className="right" onClick={() => navgaitonto.open('/cocNewsDetail', `id=${data1[2].id}`)} >
                            <div className="photo" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img src={data1[2].imgs[0]} style={{ maxWidth: "100%", maxHeight: '100%' }} />
                            </div>
                            <div className="title overHidden">
                                {data1[2].title}
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: data1[2].content }} className="texts eightOverHidden" />
                        </div>
                    }
                </div >
            </div >

        );
    }
}

export default App;
