import React, { Component } from "react";
import { Popover, message } from "antd";
import Slider from "react-slick";
import TabBar from "../../../components/TabBar";
import Footer from "../../../components/Footer";
import MenberCards from "../../../components/MenberCards";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.less";
import request from "../../../utils/req";
import Loading from '../../../components/Loading';
import noDataImg from '../../../asset/images/no_data.png';
import navgaitonto from "../../../utils/navgaitonto";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

class App extends Component {
  state = {
    showSelectSession: false,
    sessionList: [],
    session: 0,
    display: true,
    width: 1014,
    positionsCurrent: 3,
    positionList: [],
    member1: {},
    member2: {},
    member3: [],
    member4: [],
    memberList: []
  };

  componentDidMount() {
    this.getSession();
    this.getPosition();
  }

  getSession = () => {
    request("sessionList", { num: 999 }, (res) => {
      if (res.code === 1000) {
        this.setState({
          sessionList: res.result.data,
        });

      } else {
        message.error("网络请求失败，请检查当前网络");
      }
    });
  };

  getPosition = (session) => {
    request("positionList", { session: session, num: 999 }, (res) => {
      if (res.code === 1000) {
        this.setState({
          positionList: res.result.data,
        });
        if (res.result.data.length > 0) {
          this.getMember(res.result.data[0].id, 1);
        }
        if (res.result.data.length > 1) {
          this.getMember(res.result.data[1].id, 2);
        }
        if (res.result.data.length > 2) {
          this.getMember(res.result.data[2].id, 3);
        }
        if (res.result.data.length > 3) {
          this.getMember(res.result.data[3].id, 4);
          this.getMember(res.result.data[3].id, 5);
        }
      } else {
        message.error("网络请求失败，请检查当前网络");
      }
    });
  }

  getMember = (id, type) => {
    const { session } = this.state;
    if (type === 5) {
      this.setState({
        loading: true
      });
    }
    let num = 1;
    switch (type) {
      case 1:
        num = 1;
        break;
      case 2:
        num = 1;
        break;
      case 3:
        num = 3;
        break;
      case 4:
        num = 3;
        break;
      case 5:
        num = 10;
        break;
      default:
        break;
    }
    request("memberList", { num: num, session: this.state.sessionList[session], position_id: id }, (res) => {
      if (res.code === 1000) {
        // member1: {},
        // member2: {},
        // member3: [],
        // member4: [],
        // memberList: []
        if (res.result.data.length === 0) {
          this.setState({
            loading: false
          });
          return;
        }
        if (type === 1) {
          this.setState({
            member1: res.result.data[0],
          });
        } else if (type === 2) {
          this.setState({
            member2: res.result.data[0],
          });
        } else if (type === 3) {
          this.setState({
            member3: res.result.data,
          });
        } else if (type === 4) {
          this.setState({
            member4: res.result.data,
          });
        } else {
          this.setState({
            memberList: res.result.data,
            loading: false
          });
        }
      } else {
        this.setState({
          loading: false
        });
        message.error("网络请求失败，请检查当前网络");
      }
    });
  }
  /* 标题 */
  renderTitle = (title, type) => {
    return (
      <div className="comTitle">
        <div className="comTitleR">{title}</div>
        {type && <div className="comTitleL" onClick={() => navgaitonto.open('/memberList')}>查看更多{">"}</div>}
      </div>
    );
  };

  renderSelectSession = () => {
    const { showSelectSession, session, sessionList } = this.state;

    return (
      <div
        onClick={() => this.setState({ showSelectSession: false })}
        className="memberListIndex"
      >
        <div className="title">届数</div>
        <div className="sessionList">
          {sessionList.map((item, index) => (
            <div
              onClick={() => {
                this.setState({
                  session: index,
                  positionsCurrent: 3,
                  positionList: [],
                  member1: {},
                  member2: {},
                  member3: [],
                  member4: [],
                  memberList: []
                }, () => this.getPosition(item))
              }}
              key={item}
              className={
                session == index
                  ? "sessionitem overHidden sessionitemse"
                  : "overHidden sessionitem"
              }
            >
              第{item}届
            </div>
          ))}
        </div>
      </div>
    );
  };

  render() {
    const { showSelectSession, session, sessionList, positionsCurrent, positionList, member1, member2, member3, member4, memberList, loading } = this.state;
    const memberList1 = [];
    if (member1.id) memberList1.push(member1);
    if (member2.id) memberList1.push(member2);
    console.log(memberList1);
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: Number(positionList.length - 3) > 4 ? 4 : positionList.length - 3,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      draggable: false
    };
    return (
      <div className="memberIndex">
        {/* 头部 */}
        <TabBar index="商会会员" />
        {/* 横线 */}
        <div className="container">
          <div className="tabBorder" />
          {/* 头部筛选 */}
          <div className="screen">
            <div
              onClick={() => {
                if (sessionList[0] === sessionList[session]) {
                } else {
                  this.setState({
                    session: 0,
                    positionsCurrent: 3,
                    positionList: [],
                    member1: {},
                    member2: {},
                    member3: [],
                    member4: [],
                    memberList: []
                  });
                  this.getPosition(0)
                }
              }}
              className={
                sessionList[0] === sessionList[session]
                  ? "nobackSessionBtn"
                  : "backSessionBtn"
              }
            >
              回到当届
            </div>
            <div className="gotoBtn">
              <div
                onClick={() => {
                  if (Number(sessionList.length) === session + 1) return;
                  this.setState({
                    session: session + 1,
                    positionsCurrent: 3,
                    positionList: [],
                    member1: {},
                    member2: {},
                    member3: [],
                    member4: [],
                    memberList: []
                  }, () => this.getPosition(sessionList[session + 1]));
                }}
                className={
                  sessionList.length === session + 1
                    ? "nextSessionBtn noSessionBtn"
                    : "nextSessionBtn"
                }
              >
                上一届
              </div>
              <div className="nowSession">
                {sessionList[0] === sessionList[session]
                  ? `当前届(第${sessionList[session] || 1}届)`
                  : `第${sessionList[session] || 1}届`}
              </div>
              <div
                onClick={() => {
                  if (sessionList[0] === sessionList[session]) return;
                  this.setState({
                    session: session - 1,
                    positionsCurrent: 3,
                    positionList: [],
                    member1: {},
                    member2: {},
                    member3: [],
                    member4: [],
                    memberList: []
                  }, () => this.getPosition(sessionList[session - 1]));
                }}
                className={
                  sessionList[0] === sessionList[session]
                    ? "nextSessionBtn noSessionBtn"
                    : "nextSessionBtn"
                }
              >
                下一届
              </div>
            </div>
            <Popover
              visible={showSelectSession}
              onVisibleChange={(e) => this.setState({ showSelectSession: e })}
              content={this.renderSelectSession()}
              trigger="click"
              placement="bottomRight"
            >
              <div className="selectSession">
                第{sessionList[session] || 1}届
                <div className="bottomClass" />
              </div>
            </Popover>
          </div>
          {
            member1.length === 0 && member3.length === 0 && memberList.length === 0 && loading !== true ? <div style={{ display: 'flex', justifyContent: 'center' }} >
              <img src={noDataImg} />
            </div> : ''
          }
          {
            member1 && <div className="positonFirst">
              {
                memberList1.map((item, index) => (
                  <div className="positonFirstCard" key={item.id} onClick={() => navgaitonto.open('/MemberInfo', `id=${item.id}`)}>
                    {this.renderTitle(positionList[index].name)}
                    <div className="top">
                      <img src={item.avatar} className="photo" />
                      <div className="right">
                        <div className="name overHidden">
                          {item.name}
                        </div>
                        <div className="positions overHidden">
                          {item.coc_name}{item.position?.name}
                        </div>
                        {
                          item.company && <div className="positions overHidden">
                            {item.company?.company_name}-{item.company?.company_position}
                          </div>
                        }
                        {
                          item.world_tag && item.world_tag.length && <div className="positions overHidden">
                            {item.world_tag.join(',')}
                          </div> || ''
                        }
                      </div>
                    </div>
                    <div className="descTitle">个人简介</div>
                    <div className="desc sixOverHidden">
                      {item.describe}
                    </div>
                  </div>
                ))
              }
            </div>

          }
          {/* 第二个职务 */}
          {
            member3.length !== 0 && <div className="positionCardTwo">
              {this.renderTitle(positionList[2].name, true)}
              <div className="positionsBox">
                {
                  member3.map((item, index) => (
                    <div className="positonFirstCard" key={item.id} onClick={() => navgaitonto.open('/MemberInfo', `id=${item.id}`)}>
                      <div className="top">
                        <img src={item.avatar} className="photo" />
                        <div className="right">
                          <div className="name overHidden">
                            {item.name}
                          </div>
                          <div className="positions overHidden">
                            {item.coc_name}{item.position.name}
                          </div>
                          {
                            item.company && <div className="positions overHidden">
                              {item.company?.company_name}-{item.company?.company_position}
                            </div>
                          }
                          {
                            item.world_tag && item.world_tag.length && <div className="positions overHidden">
                              {item.world_tag.join(',')}
                            </div> || ''
                          }
                        </div>
                      </div>
                      <div className="desc fiveOverHidden">
                        {item.describe}
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>

          }
          {
            positionList.length > 0 && <div className="morePositionCard">
              <div className="titleTabs">
                <div className="bannerSild">
                  <div className="peryBtn btn" />
                  <div className="bannerBody">
                    <div
                      style={{
                        width: this.state.width + "px",
                        display: this.state.display ? "block" : "none",
                      }}
                    >
                      <Slider {...settings}>
                        {positionList.map((item, index) => (
                          index > 2 && <div key={item.id} className={positionsCurrent == index ? "post slick-currents" : "post"}
                            onMouseDown={e => this.setState({
                              dateNow: Date.now()
                            })}
                            onClick={(e) => {
                              let isgo = this.state.dateNow < Date.now() - 200;
                              if (!isgo) {
                                this.setState({ positionsCurrent: index }, this.getMember(positionList[index].id, 5));
                              }
                            }}>{item.name}</div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                  <div className="nextBtn btn" />
                </div>

                {/* 更多 */}
                <div className="moreBtn" onClick={() => navgaitonto.open('/memberList', `session=${session}`)}>更多{">>"}</div>
              </div>
              <div className="positionBody">
                {loading ? <Loading /> : memberList.length === 0 ?
                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }} >
                    <img src={noDataImg} />
                  </div>
                  : memberList.map(item => (
                    <MenberCards data={item} key={item.id} />
                  ))}
              </div>
            </div>
          }
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
