import React, { Component } from "react";
import dateUtils from "../../utils/time/time";
import "./index.less";

class App extends Component {
  state = {
    data: {},
  };
  componentWillReceiveProps(nextProps) {
    this.setState({
      data: nextProps.data,
    });
  }
  render() {
    console.log(this.props, "props");
    const { data } = this.state;
    return (
      <div className="detailPage">
        <p className="title">{data.title}</p>
        <div className="timeAuthor" style={{ marginBottom: 10 }}>
          <span>发布时间：{dateUtils.getTime1(data.release_time)}</span>
          <span>责任编辑：{data.author}</span>
        </div>
        <div >
          {
            data.video && <video src={data.video} style={{ width: '100%', height: 368, marginBottom: 10 }} controls="controls" />
          }
        </div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: data.content?.replace(/'crossorigin="anonymous"'/g, ' ') }}
        ></div>
      </div>
    );
  }
}

export default App;
