import React, { Component } from "react";
import { Popover, message, Pagination } from "antd";
import TabBar from "../../../components/TabBar";
import Footer from "../../../components/Footer";
import MenberCards from "../../../components/MenberCards";
import Loading from "../../../components/Loading";
import "./index.less";
import request from "../../../utils/req";

class App extends Component {
  state = {
    showSelectSession: false,
    sessionList: [],
    positionList: [],
    position: 0,
    session: 0,
    display: true,
    width: 1014,
    memberList: [],
    page: 1,
    total: 0
  };

  componentDidMount() {
    this.getSession();
  }

  getSession = () => {
    request("sessionList", { num: 999 }, (res) => {
      if (res.code === 1000) {
        this.setState({
          sessionList: res.result.data,
          session: Number(this.props.location.query.session)
        });
        this.getPosition(res.result.data[Number(this.props.location.query.session)])
      } else {
        message.error("网络请求失败，请检查当前网络");
      }
    });
  };

  getPosition = (session) => {
    request("positionList", { session: session, num: 999 }, (res) => {
      if (res.code === 1000) {
        this.setState({
          positionList: res.result.data,
          position: 0,
          data: []
        }, () => {
          if (res.result.data.length !== 0) {
            this.getMember(session, res.result.data[0].id);
          }
        });

      } else {
        message.error("网络请求失败，请检查当前网络");
      }
    });
  }

  getMember = (session, position) => {
    const { page } = this.state;
    this.setState({
      loading: true
    });
    request("memberList", { session: session, position_id: position, num: 8, p: page }, (res) => {
      if (res.code === 1000) {
        this.setState({
          memberList: res.result.data,
          loading: false,
          total: res.result.total
        });
      } else {
        this.setState({
          loading: false
        });
        message.error("网络请求失败，请检查当前网络");
      }
    });
  }
  /* 标题 */
  renderTitle = (title, type) => {
    return (
      <div className="comTitle">
        <div className="comTitleR">{title}</div>
        {type && <div className="comTitleL">查看更多{">"}</div>}
      </div>
    );
  };

  renderSelectSession = () => {
    const { showSelectSession, session, sessionList } = this.state;

    return (
      <div
        onClick={() => this.setState({ showSelectSession: false })}
        className="memberListIndexs"
      >
        <div className="title">届数</div>
        <div className="sessionList">
          {sessionList.map((item, index) => (
            <div
              onClick={() => this.setState({ session: index, position: 0, page: 1 }, () => this.getPosition(item))}
              key={item}
              className={
                session == index
                  ? "sessionitem overHidden sessionitemse"
                  : "overHidden sessionitem"
              }
            >
              第{item}届
            </div>
          ))}
        </div>
      </div>
    );
  };
  render() {
    const { showSelectSession, session, sessionList, positionList, position, memberList, page, total, loading } = this.state;
    return (
      <div className="memberLists">
        {/* 头部 */}
        <TabBar index="商会会员" />
        {/* 横线 */}
        <div className="container">
          <div className="tabBorder" />
          <div className="bodyPostion">
            <div className='leftPostion'>
              <div className="positionBox twoOverHidden">
                <div style={{ width: '100%' }} className="twoOverHidden">
                  <Popover
                    visible={showSelectSession}
                    onVisibleChange={(e) => this.setState({ showSelectSession: e })}
                    content={this.renderSelectSession()}
                    trigger="click"
                    placement="bottomRight"
                  >
                    <div className="selectSession">
                      第{sessionList[session] || 1}届
                       <div className="bottomBtn" />
                    </div>
                  </Popover>
                </div>
              </div>
              {positionList.map((item, index) => (
                <div onClick={() => this.setState({ position: index, page: 1 }, () => this.getMember(sessionList[session], positionList[index].id))} className={index === position ? "positionBox positionBoxs twoOverHidden" : "positionBox twoOverHidden"} key={item.id}>
                  <div style={{ width: '100%' }} className="twoOverHidden">
                    {item.name}
                  </div>
                </div>
              ))}
            </div>
            <div className='rightMember'>
              {positionList.length !== 0 && this.renderTitle(positionList[position].name)}
              <div className='memberList'>
                {
                  loading ? <Loading /> : memberList.map(item => (
                    <MenberCards data={item} key={item.id} />
                  ))
                }
              </div>
              <div
                style={{
                  display: total > 8 ? "flex" : "none",
                  justifyContent: "center",
                  marginBottom: 30,
                }}
              >
                <Pagination
                  current={page}
                  total={total}
                  pageSize={8}
                  onChange={(e) => this.setState({ page: e }, () => this.getMember(sessionList[session], positionList[position].id))}
                  showSizeChanger={false}
                />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div >
    );
  }
}

export default App;
