import React, { Component } from 'react';
import { Button, message, Carousel } from "antd";
import { CloseOutlined } from '@ant-design/icons';
import request from "../../utils/req";
import Loading from "../Loading";
import dates from "../../utils/time/time";

import './index.less';

class App extends Component {
    state = {
        visbled: false
    }
    componentWillReceiveProps(next) {
        this.setState({
            visbled: next.visbled
        });
        if (next.visbled) {
            this.getQrcode(next.url, next.params);
        }
    }

    getQrcode = (url, params) => {
        this.setState({ loading: true });
        request('getQrcode', { page_url: url, scene: params, }, res => {
            if (res.code === 1000) {
                this.setState({ loading: false, img: res.result });
            } else {
                this.setState({ loading: false });
            }
        });
    }
    render() {
        const { visbled, loading } = this.state;
        const { isMember } = this.props;
        return (
            <div className="repostiTitons" style={{ display: visbled ? 'flex' : "none" }}>
                <div className="box">
                    <div className="closer" onClick={() => this.props.onChange()}>
                        <CloseOutlined style={{ fontSize: 18, color: "#707070" }} />
                    </div>
                    {loading ? <Loading /> : <img src={this.state.img} className="photo" />}
                    <div className='texts'>
                        微信扫一扫{isMember ? '' : '立即报名'}
                    </div>
                </div>
            </div>)
    }
}

export default App;