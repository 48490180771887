import axios from 'axios';
import qs from 'qs';
import { sign } from './tools';
const request = (url, params, successFun) => {
  return new Promise((resolve, reject) => {

    if (window.location.href.indexOf('https://testwebsite.cocsaas.com/') !== -1 || window.location.href.indexOf('http://testwebsite.cocsaas.com/') !== -1) {
      url = 'https://testapi.chief-info.com/cocwebsite/' + url;
    } else {
      // url = 'http://192.168.168.107/cocwebsite/' + url;
      url = 'https://api.cocsaas.com/cocwebsite/' + url;
    }
    if (params === undefined) {
      params = {};
    }
    params.coc_id = window.location.search?.split('=')[1]?.split('&')[0] || 3;
    params = sign(params);
    axios
      .post(url, qs.stringify(params), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then(response => {
        successFun(response.data);
        resolve(response.data);
      })
  });



  // return requestObj(url, {
  //   method: 'POST',
  //   params: params.params,
  //   data: requestParams,
  // });
};

export default request;
