import React, { Component } from "react";
import { Button, message, Carousel } from "antd";
import request from "../../utils/req";
import Loading from "../Loading";
import dates from "../../utils/time/time";
import navgaitonto from "../../utils/navgaitonto";


import "./index.less";

class App extends Component {
  state = {
    data1: {},
    data2: [],
    loading: false,
  };
  componentDidMount() {
    this.setState({
      loading: true,
    });
    request("articleList", { num: 5, type: 3 }, (res) => {
      if (res.code === 1000) {
        let data1 = {};
        const data2 = [];
        res.result.data.map((item, index) => {
          if (index == 0) {
            data1 = item;
          } else {
            data2.push(item);
          }
        });
        this.setState({
          data2,
          data1,
          loading: false,
        });
      } else if (res.code === -1) {
        this.setState({
          loading: false,
        });
        message.error("网络请求失败，请检查当前网络");
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }
  /* 标题 */
  renderTitle = (title, url) => {
    return (
      <div className="comTitle">
        <div className="comTitleR">{title}</div>
        <div className="comTitleL" onClick={() => navgaitonto.go(url)}>查看更多{">"}</div>
      </div>
    );
  };

  render() {
    const { data2, data1, loading, colorList = [], } = this.state;
    return (
      <div className="partyNewsCard">
        {this.renderTitle('党建新闻', '/partyNewsList')}
        {loading ? (
          <Loading />
        ) : (
            <>
              {data1.id && (
                <div className="first hoverCored" onClick={() => {
                  this.setState({
                    colorList: [...colorList, data1.id]
                  });
                  navgaitonto.open('/partyNewsDetail', `id=${data1.id}`);
                }}>
                  <img src={data1.imgs[0]} className="img" />
                  <div className="newsContent">
                    <div>
                      <div className="pTitle overHidden hoverCored" style={{ color: colorList.indexOf(data1.id) === -1 ? "#333" : "#666" }}>
                        {data1.title}
                      </div>
                      <div className="pContainer twoOverHidden hoverCored">
                        {data1.content}
                      </div>
                    </div>

                    <div className="dates hoverCored">
                      {dates.getMonthDay1(data1.release_time)}
                    </div>
                  </div>
                </div>
              )}

              {data2.map((item) => (
                <div className="newLists hoverCored" key={item.id} onClick={() => {
                  this.setState({
                    colorList: [...colorList, item.id]
                  });
                  navgaitonto.open('/partyNewsDetail', `id=${item.id}`)
                }}>
                  <div className="right hoverCored overHidden" style={{ color: colorList.indexOf(item.id) === -1 ? "#333" : "#666" }}>{item.title}</div>
                  <div className="left hoverCored">
                    {dates.getMonthDay1(item.release_time)}
                  </div>
                </div>
              ))}
            </>
          )}
      </div>
    );
  }
}

export default App;
