import React, { Component } from "react";
import { Pagination, message } from "antd";
import TabBar from "../../../components/TabBar";
import Footer from "../../../components/Footer";
import HotNews from "../../../components/HotNews";
import Detail from "../../../components/Detail";
import NewsHotRec from "../../../components/NewsHotRec";
import request from "../../../utils/req";
import navgaitonto from "../../../utils/navgaitonto";
import "./index.less";

class App extends Component {
  state = {
    articleInfo: {},
    newsList: [],
  };
  componentWillMount() {
    this.getInfo(this.props.location.query.id);
    this.getList();
  }

  getInfo(id) {
    request("articleInfo", { id: id }, (res) => {
      if (res.code === 1000) {
        this.setState({
          articleInfo: res.result,
        });
      } else if (res.code === -1) {
        this.setState({
          loading: false,
        });
        message.error("网络请求失败，请检查当前网络");
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }
  getList() {
    request("articleList", { num: 4, top: 1, type: 1 }, (res) => {
      if (res.code === 1000) {
        this.setState({
          newsList: res.result.data,
          loading: false,
        });
      } else if (res.code === -1) {
        this.setState({
          loading: false,
        });
        message.error("网络请求失败，请检查当前网络");
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }
  /* 标题 */
  renderTitle = (title, url) => {
    return (
      <div className="comTitle">
        <div className="comTitleR">{title}</div>
        <div className="comTitleL">查看更多</div>
      </div>
    );
  };
  render() {
    const { articleInfo, newsList } = this.state;
    console.log(newsList, "newsList");
    return (
      <div className="partyInfo">
        {/* 头部 */}
        <TabBar index="党建中心" />
        <div className="container">
          <div className="redBar"></div>
          <div className="location"><span style={{ cursor: 'pointer' }} onClick={() => navgaitonto.go('/partyContent')}>党建中心</span>&gt;党建新闻</div>
          <div className="detailContent">
            <div className="detail">
              <Detail data={articleInfo}></Detail>
            </div>
            <div className="hotNews">
              <NewsHotRec list={newsList}></NewsHotRec>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
