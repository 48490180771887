import React, { Component } from 'react';
import TabPane from '../TabPane';
import dates from '../../utils/time/time';
import request from '../../utils/req';
import './index.less';
import zBgc from '../../asset/images/zBgc.png'; // 招标
import xBgc from '../../asset/images/xbgc.png'; // 招标
import cBgc from '../../asset/images/cBgc.png'; // 招标
import gBgc from '../../asset/images/gBgc.png'; // 招标
class App extends Component {
    state = {
        showCode: false

    }
    componentDidMount() {
        console.log(this.props);
    }

    renderTitle = (title, url) => {
        return (
            <div className='comTitle'>
                <div className="comTitleR">
                    {title}
                    <TabPane data={['全部', '供应', '采购', '需求', '招标']} onChange={e => console.log(e)} />
                </div>
                <div className="comTitleL">
                    查看更多{'>'}
                </div>
            </div>
        );
    }

    renderType(type, bg) {
        if (type === 2) {
            if (bg) {
                return { backgroundColor: '#B3CFF6', color: '#0055B8' };
            }
            return "采购";
        }
        if (type === 3) {
            if (bg) {
                return { backgroundColor: '#F6B3B3', color: '#B80000' };
            }
            return "需求";
        }
        if (type === 1) {
            if (bg) {
                return { backgroundColor: '#B6F6B3', color: '#00B823' };
            }
            return "供应";
        }
        if (type === 4) {
            if (bg) {
                return { backgroundColor: '#FFDEB2', color: '#FCA500' };
            }
            return "招标";
        }
    }
    getQrcode = (url, p) => {
        if (!this.state.img) {
            this.setState({ loading: true });
            request('getQrcode', { page_url: url, scene: p }, res => {
                if (res.code === 1000) {
                    this.setState({ loading: false, img: res.result });
                } else {
                    this.setState({ loading: false });
                }
            });
        }
    }

    render() {
        const { showCode, img } = this.state;
        const { type, data } = this.props;
        const renderImgs = (type) => {
            if (type === 1) {
                return gBgc;
            }
            if (type === 2) {
                return cBgc;
            }
            if (type === 3) {
                return xBgc;
            }
            if (type === 4) {
                return zBgc;
            }
        }
        return (
            <div className="opportunityitem"
                onMouseDown={e => this.setState({
                    dateNow: Date.now()
                })}
                onClick={(e) => {
                    let isgo = this.state.dateNow < Date.now() - 200;
                    if (!isgo) {
                        console.log(data.type,"asdasdasd");
                        if (data.type === 3) {
                            this.getQrcode(`opportunity/pages/detail/buyNeedDetail/index`, `id=${data.bo_id}&typeFlag=${3}`);
                        }
                        if (data.type === 2) {
                            this.getQrcode(`opportunity/pages/detail/buyNeedDetail/index`, `id=${data.bo_id}&typeFlag=${2}`);
                        }
                        if (data.type === 1) {
                            this.getQrcode(`opportunity/pages/detail/supplyDetail/index`, `id=${data.bo_id}`);
                        }
                        if (data.type === 4) {
                            this.getQrcode(`opportunity/pages/detail/biddingDetail/index`, `id=${data.bo_id}`);
                        }
                        this.setState({ showCode: true });
                    }
                }}>
                <div onMouseLeave={e => this.setState({ showCode: false })} className="codePhoto" style={{ display: showCode ? "flex" : 'none', }} onClick={e => {
                    e.stopPropagation();
                    this.setState({
                        showCode: false
                    });
                }}>
                    <img src={img} className="cPhoto" />
                    <div className="tips">
                        打开微信扫一扫
                    </div>
                    <div className="tips">
                        查看更多信息
                    </div>
                </div>
                {/* 类型 */}
                <div className="type" style={this.renderType(type, true)}>
                    {this.renderType(type)}
                </div>
                {/* 图片 */}
                <div className="photo" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
                    {
                        data.cover ?
                            <div className="cocNewsBgc" style={{ backgroundImage: `url(${data.cover || renderImgs(data.type)})`, paddingBottom: '100%' }}></div>
                            : <img src={renderImgs(data.type)} style={{ width: "100%", height: '100%' }} />
                    }
                </div>
                {/* 标题 */}
                <div className='title twoOverHidden'>
                    {data.title}
                </div>
                {/* 预算 */}
                <div className="price overHidden">
                    {data.type === 1 ? "单价" : "预算"}：{data.budget}元
                </div>
                {/* 数量 */}
                <div className="price overHidden">
                    数量：{data.num}{data.spec}
                </div>
                {/* 剩余天数 */}
                <div className="price overHidden">
                    剩余天数：{dates.getRemainingDay(data.end_time) > 0 ? `${dates.getRemainingDay(data.end_time)}天` : '已结束'}
                </div>
                {/* 发布时间 */}
                <div className="price overHidden">
                    发布时间：{dates.dateDay(data.add_time)}
                </div>
            </div >)
    }
}

export default App;